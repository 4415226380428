import Sidebar from 'components/Sidebar'
import Header from 'components/Header'

import '../components/style.css'
import React, { useRef, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, FormControlLabel, FormLabel, Radio, RadioGroup, Input, Alert, 
  Snackbar, AlertColor, Autocomplete, TextField, MenuItem, Select, FormControl, Grid, InputLabel, TextareaAutosize as BaseTextareaAutosize, styled
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { GoogleApiService } from 'data/GoogleApiService';
import { RepositoryImpl } from 'data/RepositoryImpl';
import { CurrentPinPuller, MapWithMarker } from 'components/MapWithMarker';
import { FromToTimeRange } from 'components/FromToTimeRange';
import { UsState } from 'components/UsStates';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

function BusinessInfo(props: any) {
  
  const ref = useRef<CurrentPinPuller>(null);
  const user = RepositoryImpl((state) => state.loggedInUser)
  const selectedLocations = RepositoryImpl((state) => state.selectedLocations)
  const [categories, setCategories] = useState([]);
  const [locationEditInfo, setLocationEditInfo] = useState<any>();
  const [newlocationEditCategories, setNewLocationEditCategories] = useState([{displayName: '',name: ''}]);
  const [existingLocationEditCategories, setExistingLocationEditCategories] = useState([{displayName: '',name: ''}]);
  const [selectedTab, setSelectedTab] = useState('1');
  const [alertMsg, setAlertMsg] = useState('');
  const [alertType, setAlertType] = useState<AlertColor>('success');
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [noneLocationSelection, setNoneLocationSelection] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const locationId = selectedLocations ? selectedLocations[0]?.split("locations/")[1] : ''
  const [locationInfo, setLocationInfo] = useState({
    name: '',
    title: '',
    phone_number: '',
    profile: {
      description: ''
    },
    phoneNumbers: {
      primaryPhone: ''
    },
    latlng: {
      latitude: 0,
      longitude: 0
    },
    website: [''],
    websiteUri: '',
    social_profiles: {
      YouTube: '',
      Instagram: '',
      Facebook: ''
    },
    address: '',
    storefrontAddress: {
      locality: '',
      administrativeArea: '',
      regionCode: '',
      postalCode: '',
      addressLines: ['']
    },
    latitude: 0,
    longitude: 0,
    description: '',
    menu_url: '',
    regularHours: {
      periods: [{
        openDay: '',
        openTime: {
          hours: 0,
          minutes: 0
        },
        closeDay: '',
        closeTime: {
          hours: 0,
          minutes: 0
        }
      }]
    },
    openInfo: {
      status: '',
      openingDate: {
        year: 0,
        month: 0,
        day: 0
      }
    },
    categories: {
      primaryCategory: {
        displayName: ''
      },
      additionalCategories: [
        {
          displayName: ''
        }
      ]
    },
    open_hours: [{
      open: '',
      close: ''
    }],
    attributes: [
      {
        name: '',
        valueType: '',
        values: []
      }
    ]
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    
    if (isEditing && window.confirm('Your changes do not save yet. Please save before change tab, or it will be lost. Click OK to continue or Cancel to stay.')) {
      setSelectedTab(newValue)
      setIsEditing(false)
    } else if (!isEditing) {
      setSelectedTab(newValue)
    }
  }

  const handleEditClick = () => {
    setIsEditing(true)
    setLocationEditInfo({})
    let existingCategories: any = []
    existingCategories.push(locationInfo.categories?.primaryCategory);
    if (locationInfo.categories?.additionalCategories) {
      existingCategories = existingCategories.concat(locationInfo.categories?.additionalCategories);
    }
    setNewLocationEditCategories([{displayName: '', name: ''}])
    setExistingLocationEditCategories(existingCategories)
  }

  const onHourChange = (data: any, dayOfWeek: string) => {
    console.log("onHourChange::", data, dayOfWeek)
  }

  // submit
  const handleSaveClick = (tab: string) => {
    if (user?.accessToken && locationId) {
      let body: any = {}
      let updateMask = [];
      if (tab === 'about') {
        if(locationEditInfo?.profileDescription?.isChanged) {
          let temp = {
            "description": locationEditInfo.profileDescription.value
          }
          body.profile = temp
          updateMask.push("profile.description")
        }
        if(locationEditInfo?.title?.isChanged) {
          body.title = locationEditInfo.title.value
          updateMask.push('title')
        }
        if(locationEditInfo?.categories?.isChanged) {
          let additionalCategoriesTemp = [];
          let hasPrimary = false
          body.categories = {};
          if (existingLocationEditCategories.length > 0) {
            body.categories.primaryCategory = existingLocationEditCategories[0]
            hasPrimary = true
            for (let i=1; i < existingLocationEditCategories.length; i++) {
              if (existingLocationEditCategories[i]?.name) {
                additionalCategoriesTemp.push(existingLocationEditCategories[i]);
              }
            }
          }
          if (newlocationEditCategories.length > 0) {
            let start = 0
            if (!hasPrimary) {
              body.categories.primaryCategory = newlocationEditCategories[0]
              start = 1
            }
            for (let i=start; i < newlocationEditCategories.length; i++) {
              if (newlocationEditCategories[i]?.name) {
                additionalCategoriesTemp.push(newlocationEditCategories[i]);
              }
            }
          }
          if (additionalCategoriesTemp.length > 0) {
            body.categories.additionalCategories = additionalCategoriesTemp
          }
          updateMask.push('categories')
        }
      }
      if (tab === 'contact') {
        if(locationEditInfo?.phoneNumberPrimary?.isChanged) {
          let temp = {
            "primaryPhone": locationEditInfo.phoneNumberPrimary.value
          }
          body.phoneNumbers = temp
          updateMask.push('phoneNumbers')
        }
        if(locationEditInfo?.websiteUri?.isChanged) {
          body.websiteUri = locationEditInfo?.websiteUri?.value
          updateMask.push('websiteUri')
        }
      }
      if (selectedLocations?.length > 1 && !window.confirm('Warning You Are Editing Multiple Locations - Are You Sure You Want To Proceed?')) {
        setIsEditing(false)
        return
      }
      console.log('Sending patch location::body=', body)
      GoogleApiService.patchLocationDetails(user?.accessToken, locationId, updateMask.join(), body).then(res => {
        console.log('success::', res)
        showAlert('success', 'Save successfully.')
        fetchLocationInfo(user?.accessToken)
        setIsEditing(false)
      }).catch(err => {
        console.error('patchLocationDetails error::', err)
        showAlert('error', 'Save failed.')
        setIsEditing(false)
      })
    }
    
  }

  const handleCancelClick = () => {
    setIsEditing(false)
  }

  const showAlert = (type: string, msg: string) => {
    setAlertType(type as AlertColor)
    setAlertMsg(msg)
    setShowAlertMessage(true)
  }

  const debounce = (fn: any, delay = 1000) => { 
    let timerId: any = null; 
    return (...args: any) => { 
        clearTimeout(timerId); 
        timerId = setTimeout(() => fn(...args), delay); 
    }; 
 }; 

  const fetchCategoriesAndPopulate = (searchText: any) => {
    console.log('searchText::', searchText)
    if (user?.accessToken) {
      GoogleApiService.getCategoriesList(user?.accessToken, searchText).then(res => {
        console.log('getCategoriesList::res=', res)
        setCategories(res?.categories ? res?.categories : [])
      })
    }
  }

  const handleCategoryChange = debounce(fetchCategoriesAndPopulate, 1000);

  const handleFieldChange = (event: any, fielName: string) => {
    let value = event.target.value;
    let obj = {
      isChanged: true,
      value
    }
    let temp = {...locationEditInfo}
    temp[fielName] = obj
    setLocationEditInfo(temp)
  };

  const displayRegularHour = (dayofWeek: any) => {
    let foundDay = locationInfo.regularHours?.periods.find(period => period.openDay.toLocaleLowerCase() === dayofWeek.toLocaleLowerCase())
    if (foundDay) {
      if (foundDay?.closeTime?.hours == 24) {
        return `Open 24 hours`
      }
      return `${foundDay?.openTime?.hours}:${foundDay?.openTime?.minutes ?? '00'} - ${foundDay?.closeTime?.hours}:${foundDay?.closeTime?.minutes ?? '00'}`
    } else {
      return 'Closed'
    }
  }

  const displayAccessibility = (accessibility: string): boolean => {
    let found = locationInfo.attributes?.find(attr => attr.name.toLocaleLowerCase().includes(accessibility.toLocaleLowerCase()))
    if (!found) {
      return false
    }
    return found.values[0]
  }

  const displayOpeningDate = () => {
    if (locationInfo.openInfo?.openingDate) {
      return `${getMonth(locationInfo.openInfo?.openingDate.month)} ${locationInfo.openInfo?.openingDate?.day}, ${locationInfo.openInfo?.openingDate?.year}`
    }
  }

  const displayServiceArea = () => {
    if (locationInfo?.storefrontAddress?.locality) {
      return `${locationInfo?.storefrontAddress?.locality}, ${locationInfo?.storefrontAddress?.administrativeArea}, ${locationInfo?.storefrontAddress?.regionCode}`
    }
  }

  const getMonth = (num: number) => {
    const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return month[num + 1]
  }

  const addAnotherCategory = () => {
    let clonedArray = JSON.parse(JSON.stringify(newlocationEditCategories))
    clonedArray.push({displayName: '', name: ''})
    setNewLocationEditCategories(clonedArray)

    setCategoriesIsChanged()
  }

  const removeCategory = (index: number) => {
    console.log('removeCategory::index=', index)
    let clonedArray = JSON.parse(JSON.stringify(newlocationEditCategories))
    clonedArray.splice(index, 1)
    setNewLocationEditCategories(clonedArray)

    setCategoriesIsChanged()
  }

  const removeExistingCategory = (index: number) => {
    console.log('removeExistingCategory::index=', index)
    let clonedArray = JSON.parse(JSON.stringify(existingLocationEditCategories))
    clonedArray.splice(index, 1)
    setExistingLocationEditCategories(clonedArray)

    setCategoriesIsChanged()
  }

  const setSelectedNewCategory = (val: any, index: number) => {
    let clonedArray = JSON.parse(JSON.stringify(newlocationEditCategories))
    clonedArray[index] = val
    setNewLocationEditCategories(clonedArray)

    setCategoriesIsChanged()
  }

  const setCategoriesIsChanged = () => {
    // mark isChanged = true
    let obj = {
      isChanged: true,
      value: null
    }
    let temp = {...locationEditInfo}
    temp['categories'] = obj
    setLocationEditInfo(temp)
  }


  React.useEffect(() => {
    console.log('selectedLocations::', selectedLocations[0])
    // let locationId = selectedLocations ? selectedLocations[0]?.split("locations/")[1] : null
    if (!selectedLocations[0]) {
      setNoneLocationSelection(true)
      return
    }
    if (user?.accessToken && locationId && selectedLocations[0]) {
      setNoneLocationSelection(false)
      setLoading(true)
      fetchLocationInfo(user?.accessToken)
    }
  }, [selectedLocations])

  const fetchLocationInfo = (accessToken: string) => {
    setLoading(true)
    GoogleApiService.getLocationDetailsAndAttributes(accessToken, locationId).then(location => {
      console.log('location info::', location)
      setLoading(false)
      setLocationInfo(location as any)
    });
  }

  return (
    <div className="container">
      <Backdrop
          sx={{
            zIndex: 20
          }}
          open={loading}
        >
          <CircularProgress color='inherit' />
      </Backdrop>
      <Snackbar
        open={showAlertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => {
          setShowAlertMessage(false);
        }}
      >
        <Alert severity={alertType}>{alertMsg}</Alert>
      </Snackbar>
      <Sidebar />
      <div className="content-area">
        <Header />
        <div className="hero biz-info">
        {noneLocationSelection && <b>
          Please select at lease one location.
        </b>}
        {
        !loading && !noneLocationSelection &&  <div>      
          {selectedLocations?.length > 1 && <div style={{display: 'flex'}}><div style={{fontSize: 'larger', marginRight: '10px'}}>Now Editing:</div> <div style={{fontWeight: 'bold', fontSize: 'larger'}}>{selectedLocations?.length} Locations</div></div>}
          <h2>Business information</h2>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label="About" value="1" />
                <Tab label="Contact" value="2" />
                <Tab label="Location" value="3" />
                <Tab label="Hours" value="4" />
                <Tab label="More" value="5" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <h3>About your business</h3>
              <div className='session-item'>
                <b>Business name</b>
                {!isEditing && <div className='session-item-attr'>{locationInfo?.title}</div>}
                {isEditing && <div className='session-item-attr'><Input style={{width: '100%'}} defaultValue={locationInfo?.title} onChange={(e) => handleFieldChange(e, 'title')}/></div>}
              </div>
              <div className='session-item'>
                <b>Business Category</b>
                {!isEditing && <div className='session-item-attr' style={{ display: 'flex' }}>
                    <div style={{ marginRight: '20px' }}>{locationInfo.categories?.primaryCategory?.displayName}</div>
                    <div className='session-item-cat-primary'>PRIMARY</div>
                </div>}
                {
                  isEditing && <>{existingLocationEditCategories?.[0]?.displayName} {existingLocationEditCategories?.map((val, index) => <div style={{display:'flex', marginBottom:'5px', marginTop: '10px'}}>
                    <div className='session-item-attr' key={index}><TextField sx={{ width: 300 }} value={val.displayName} label="Category" onChange={(e) => handleCategoryChange(e.target.value)}/></div>
                    <Button variant="contained" color="error" style={{marginLeft: '5px'}} onClick={() => removeExistingCategory(index)}>Remove</Button></div>)}</>
                }
                {
                  isEditing && <>{newlocationEditCategories?.map((value, index) => <div style={{display:'flex', marginBottom:'5px'}}><div className='session-item-attr' key={index}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={categories}
                      getOptionLabel={(option: any) => option?.displayName}
                      isOptionEqualToValue={(option, value) => option?.displayName == value?.displayName}
                      value={value}
                      defaultValue={value}
                      onChange={(event: any, newValue: any) => {
                        setSelectedNewCategory(newValue, index);
                      }}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Category" value={value?.displayName} onChange={(e) => handleCategoryChange(e.target.value)}/>}
                    />
                    </div><Button variant="contained" color="error" style={{marginLeft: '5px'}} onClick={() => removeCategory(index)}>Remove</Button></div>)}
                  <div style={{marginTop: '10px'}}>
                    <Button variant="contained" onClick={() => addAnotherCategory()}>Add another category</Button>
                  </div></>
                }
                

                {
                  !isEditing && locationInfo.categories?.additionalCategories?.map((value, index) => <div className='session-item-attr' key={index}>{value.displayName}</div>)
                }
                {/* {
                  isEditing && locationInfo.categories?.additionalCategories?.map((value, index) => <div className='session-item-attr' key={index}><Input style={{minWidth: '300px'}} defaultValue={value.displayName}/></div>)
                } */}
              </div>
              <div className='session-item'>
                <b>Description</b>
                {!isEditing && <div className='session-item-attr'>{locationInfo?.profile?.description}</div> }
                {isEditing && <div className='session-item-attr'><Textarea defaultValue={locationInfo?.profile?.description} onChange={(e) => handleFieldChange(e, 'profileDescription')}/></div>}
              </div>
              <div className='session-item' style={{borderBottom: 'unset'}}>
                <b>Opening Date</b>
                {!isEditing && <div className='session-item-attr'>{displayOpeningDate()}</div>}
                {isEditing && <div className='session-item-attr'><Input type='date'/></div>}
              </div>
              {
                !isEditing && <Button style={{float: 'right'}} variant="contained" onClick={() => handleEditClick()}>Edit</Button>
              }
              {
                isEditing && <div style={{float: 'right'}}>
                  <Button style={{marginRight: '10px'}} variant="contained" onClick={() => handleSaveClick('about')}>Save</Button>
                  <Button variant="contained" onClick={() => handleCancelClick()}>Cancel</Button>
                </div>
              }
            </TabPanel>
            <TabPanel value="2">
              <h3>Contact information</h3>
              <div className='session-item'>
                <b>Phone number</b>
                {!isEditing && <div className='session-item-attr'>{locationInfo.phoneNumbers?.primaryPhone}</div>}
                {isEditing && <div><Input style={{minWidth: '300px'}} defaultValue={locationInfo.phoneNumbers?.primaryPhone} onChange={(e) => handleFieldChange(e, 'phoneNumberPrimary')}/></div>}
              </div>
              <div className='session-item'>
                <b>Website</b>
                {!isEditing && <div className='session-item-attr'>{locationInfo.websiteUri}</div>}
                {isEditing && <div><Input style={{width: '100%'}} defaultValue={locationInfo.websiteUri} onChange={(e) => handleFieldChange(e, 'websiteUri')}/></div>}
              </div>
              <div className='session-item' style={{borderBottom: 'unset', marginBottom: '10px'}}>
                <b>Social profiles</b>
                {
                  locationInfo.social_profiles?.YouTube && <div className='session-item-attr'>{locationInfo.social_profiles?.YouTube}</div>
                }
                {
                  locationInfo.social_profiles?.Instagram && <div className='session-item-attr'>{locationInfo.social_profiles?.Instagram}</div>
                }
                {
                  locationInfo.social_profiles?.Facebook && <div className='session-item-attr'>{locationInfo.social_profiles?.Facebook}</div>
                }
                {
                  isEditing && 
                  <div>
                    <div style={{lineHeight: '2rem', marginBottom: '5px'}}><div className='contact-social'>Youtube</div><Input onChange={(e) => handleFieldChange(e, 'socialYoutube')} style={{width: '100%'}} defaultValue={locationInfo.social_profiles?.YouTube}/></div>
                    <div style={{lineHeight: '2rem', marginBottom: '5px'}}><div className='contact-social'>Instagram</div><Input onChange={(e) => handleFieldChange(e, 'socialInstagram')} style={{width: '100%'}} defaultValue={locationInfo.social_profiles?.Instagram}/></div>
                    <div style={{lineHeight: '2rem', marginBottom: '5px'}}><div className='contact-social'>Facebook</div><Input onChange={(e) => handleFieldChange(e, 'socialFacebook')} style={{width: '100%'}} defaultValue={locationInfo.social_profiles?.Facebook}/></div>
                  </div>
                  }
              </div>
              {
                !isEditing && <Button style={{float: 'right'}} variant="contained" onClick={() => handleEditClick()}>Edit</Button>
              }
              {
                isEditing && <div style={{float: 'right'}}>
                  <Button style={{marginRight: '10px'}} variant="contained" onClick={() => handleSaveClick('contact')}>Save</Button>
                  <Button variant="contained" onClick={() => handleCancelClick()}>Cancel</Button>
                </div>
              }
            </TabPanel>
            <TabPanel value="3">
              <h3>Location and areas</h3>
              <div className='session-item'>
                <b>Business Location</b>
                {!isEditing && <Box
                  sx={{
                    width: 600,
                    height: 400,
                    marginLeft: 2
                  }}
                >
                <MapWithMarker 
                  markerRef = {ref}
                  showMarker={true}
                  markerLattitude={locationInfo.latlng?.latitude} 
                  markerLongitude={locationInfo.latlng?.longitude}
                /></Box>}
                {isEditing && <div style={{marginTop: '10px'}}>
                  <FormControl><InputLabel id='streetAddr'>Street Address</InputLabel><Input id="streetAddr" style={{width: '100%'}} defaultValue={locationInfo?.storefrontAddress?.addressLines?.[0]} onChange={(e) => handleFieldChange(e, 'streetAddr')}/></FormControl><br/><br/>
                  <FormControl><InputLabel id='streetAddr2'>Street Address line 2</InputLabel><Input id="streetAddr" style={{width: '100%'}} defaultValue={locationInfo?.storefrontAddress?.addressLines?.[1]} onChange={(e) => handleFieldChange(e, 'streetAddr2')}/></FormControl><br/><br/>
                  <FormControl><InputLabel id='city'>City</InputLabel><Input id="streetAddr" style={{width: '100%'}} defaultValue={locationInfo?.storefrontAddress?.locality} onChange={(e) => handleFieldChange(e, 'city')}/></FormControl>
                  <div style={{display: 'flex', marginTop: '10px'}}>
                    <UsState defaultValue={locationInfo?.storefrontAddress?.administrativeArea} onChange={(data: any) => console.log(data)}/>
                    <FormControl><InputLabel id='zipCode'>Zip code</InputLabel><Input id="streetAddr" style={{width: '100%'}} defaultValue={locationInfo?.storefrontAddress?.postalCode} onChange={(e) => handleFieldChange(e, 'zipCode')}/></FormControl>
                  </div>
                </div>}
              </div>
              <div className='session-item' style={{borderBottom: 'unset', marginBottom: '10px'}}>
                <b>Service area</b>
                {!isEditing && <div className='session-item-attr'>{displayServiceArea()}</div>}
                {isEditing && <div className='session-item-attr' style={{marginTop: '10px'}}><FormControl><InputLabel id='serviceArea'>Service area</InputLabel><Input id="serviceArea" style={{width: '100%'}} onChange={(e) => handleFieldChange(e, 'serviceArea')}/></FormControl></div>}
              </div>
              {
                !isEditing && <Button style={{float: 'right'}} variant="contained" onClick={() => handleEditClick()}>Edit</Button>
              }
              {
                isEditing && <div style={{float: 'right'}}>
                  <Button style={{marginRight: '10px'}} variant="contained" onClick={() => handleSaveClick('location')}>Save</Button>
                  <Button variant="contained" onClick={() => handleCancelClick()}>Cancel</Button>
                </div>
              }
            </TabPanel>
            <TabPanel value="4">
              <h3>Opening hours</h3>
              {
                !isEditing && <div className='session-item'>
                  <b style={{ marginBottom: '5px' }}>Hours</b>
                  <div className='session-item-attr' style={{ marginBottom: '5px' }}>Opening with main hours</div>
                  <div style={{ marginBottom: '5px' }}><div style={{float: 'left', minWidth: '150px'}}>Sunday</div><div style={{minWidth: '100px'}}> {displayRegularHour('sunday')}</div></div>
                  <div style={{ marginBottom: '5px' }}><div style={{float: 'left', minWidth: '150px'}}>Monday</div><div style={{minWidth: '100px'}}>{displayRegularHour('monday')}</div></div>
                  <div style={{ marginBottom: '5px' }}><div style={{float: 'left', minWidth: '150px'}}>Tuesday</div><div style={{minWidth: '100px'}}> {displayRegularHour('tuesday')}</div></div>
                  <div style={{ marginBottom: '5px' }}><div style={{float: 'left', minWidth: '150px'}}>Wednesday</div><div style={{minWidth: '100px'}}> {displayRegularHour('wednesday')}</div></div>
                  <div style={{ marginBottom: '5px' }}><div style={{float: 'left', minWidth: '150px'}}>Thursday</div><div style={{minWidth: '100px'}}> {displayRegularHour('thursday')}</div></div>
                  <div style={{ marginBottom: '5px' }}><div style={{float: 'left', minWidth: '150px'}}>Friday</div><div style={{minWidth: '100px'}}> {displayRegularHour('friday')}</div></div>
                  <div style={{ marginBottom: '5px' }}><div style={{float: 'left', minWidth: '150px'}}>Saturday</div><div style={{minWidth: '100px'}}> {displayRegularHour('saturday')}</div></div>
                </div>
              }
              {
                isEditing && <div className='session-item'>
                  <b style={{ marginBottom: '5px' }}>Hours</b>
                  <div className='session-item-attr' style={{ marginBottom: '10px' }}>Opening with main hours</div>
                  <div style={{ marginBottom: '5px' }}><div style={{float: 'left', minWidth: '150px'}}>Sunday</div><FromToTimeRange fromLabel="Opens at" toLabel="Closes at" onChange={(data: any) => onHourChange(data, "sunday")}/></div>
                  <div style={{ marginBottom: '5px' }}><div style={{float: 'left', minWidth: '150px'}}>Monday</div><FromToTimeRange fromLabel="Opens at" toLabel="Closes at" onChange={(data: any) => onHourChange(data, "monday")}/></div>
                  <div style={{ marginBottom: '5px' }}><div style={{float: 'left', minWidth: '150px'}}>Tuesday</div><FromToTimeRange fromLabel="Opens at" toLabel="Closes at" onChange={(data: any) => onHourChange(data, "tuesday")}/></div>
                  <div style={{ marginBottom: '5px' }}><div style={{float: 'left', minWidth: '150px'}}>Wednesday</div><FromToTimeRange fromLabel="Opens at" toLabel="Closes at" onChange={(data: any) => onHourChange(data, "wednesday")}/></div>
                  <div style={{ marginBottom: '5px' }}><div style={{float: 'left', minWidth: '150px'}}>Thursday</div><FromToTimeRange fromLabel="Opens at" toLabel="Closes at" onChange={(data: any) => onHourChange(data, "thursday")}/></div>
                  <div style={{ marginBottom: '5px' }}><div style={{float: 'left', minWidth: '150px'}}>Friday</div><FromToTimeRange fromLabel="Opens at" toLabel="Closes at" onChange={(data: any) => onHourChange(data, "friday")}/></div>
                  <div style={{ marginBottom: '5px' }}><div style={{float: 'left', minWidth: '150px'}}>Saturday</div><FromToTimeRange fromLabel="Opens at" toLabel="Closes at" onChange={(data: any) => onHourChange(data, "saturday")}/></div>
                </div>
              }
              <div className='session-item' style={{borderBottom: 'unset', marginBottom: '10px'}}>
                <b>Special hours</b>
                { !isEditing && <div className='session-item-attr'>Opening with main hours</div> }
                { isEditing && <div className='session-item-attr' style={{display: 'flex'}}>
                  <div style={{marginRight: '30px'}}><InputLabel id='from'>From</InputLabel><Input id='from' type='datetime-local'/></div>
                  <div><InputLabel id='to'>To</InputLabel><Input id='to' type='datetime-local'/></div></div> }
              </div>
              {
                !isEditing && <Button style={{float: 'right'}} variant="contained" onClick={() => handleEditClick()}>Edit</Button>
              }
              {
                isEditing && <div style={{float: 'right'}}>
                  <Button style={{marginRight: '10px'}} variant="contained" onClick={() => handleSaveClick('hours')}>Save</Button>
                  <Button variant="contained" onClick={() => handleCancelClick()}>Cancel</Button>
                </div>
              }
            </TabPanel>
            <TabPanel value="5">
              <h3>More</h3>
              <div className='session-item'>
                <b>From the business</b>
                <div className='session-item-attr'>Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps, and other Google services. If you're not the owner, confirm with the owner before adding an attribute.</div>
                <div className='session-item-attr'></div>
                <div className='session-item-attr' style={{ display: 'flex' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '18px', lineHeight: '2.5rem', minWidth: '350px' }}>Identifies as Asian-owned</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel checked={displayAccessibility('is_owned_by_asian')} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel checked={!displayAccessibility('is_owned_by_asian')} value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
                <div className='session-item-attr' style={{ display: 'flex' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '18px', lineHeight: '2.5rem', minWidth: '350px' }}>Identifies as Black-owned</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel checked={displayAccessibility('is_black_owned')} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel checked={!displayAccessibility('is_black_owned')} value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
                <div className='session-item-attr' style={{ display: 'flex' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '18px', lineHeight: '2.5rem', minWidth: '350px' }}>Identifies as disabled-owned</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel checked={displayAccessibility('is_owned_by_disability')} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel checked={!displayAccessibility('is_owned_by_disability')} value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
                <div className='session-item-attr' style={{ display: 'flex' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '18px', lineHeight: '2.5rem', minWidth: '350px' }}>Identifies as Indigenous-owned</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel checked={displayAccessibility('is_owned_by_indigenous')} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel checked={!displayAccessibility('is_owned_by_indigenous')} value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
                <div className='session-item-attr' style={{ display: 'flex' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '18px', lineHeight: '2.5rem', minWidth: '350px' }}>Identifies as Latino-owned</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel checked={displayAccessibility('is_owned_by_latinx')} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel checked={!displayAccessibility('is_owned_by_latinx')} value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
                <div className='session-item-attr' style={{ display: 'flex' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '18px', lineHeight: '2.5rem', minWidth: '350px' }}>Identifies as LGBTQ+ owned</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel checked={displayAccessibility('is_owned_by_lgbtq')} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel checked={!displayAccessibility('is_owned_by_lgbtq')} value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
                <div className='session-item-attr' style={{ display: 'flex' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '18px', lineHeight: '2.5rem', minWidth: '350px' }}>Identifies as veteran-owned</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel checked={displayAccessibility('is_owned_by_veterans')} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel checked={!displayAccessibility('is_owned_by_veterans')} value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
                <div className='session-item-attr' style={{ display: 'flex' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '18px', lineHeight: '2.5rem', minWidth: '350px' }}>Identifies as women-owned</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel checked={displayAccessibility('is_owned_by_women')} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel checked={!displayAccessibility('is_owned_by_women')} value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
              </div>
              <div className='session-item'>
                <b>Accessibility</b>
                <div className='session-item-attr'>Let customers know more about your business by showing attributes on your Business Profile. These may appear publicly on Search, Maps and other Google services.</div>
                <div className='session-item-attr' style={{ display: 'flex' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '18px', lineHeight: '2.5rem', minWidth: '350px' }}>Has assistive hearing loop</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel checked={displayAccessibility('has_assistive_hearing_loop')} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel checked={!displayAccessibility('has_assistive_hearing_loop')} value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
                <div style={{ display: 'flex' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '18px', lineHeight: '2.5rem', minWidth: '350px' }}>Has wheelchair accessible elevator</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="wheelchairaccessiblecarpark"
                  >
                    <FormControlLabel checked={displayAccessibility('has_wheelchair_accessible_elevator')} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel checked={!displayAccessibility('has_wheelchair_accessible_elevator')} value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
                <div style={{ display: 'flex' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '18px', lineHeight: '2.5rem', minWidth: '350px' }}>Has wheelchair accessible parking lot</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"    
                    name="wheelchairaccessiblecarpark"
                  >
                    <FormControlLabel checked={displayAccessibility('has_wheelchair_accessible_parking')} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel checked={!displayAccessibility('has_wheelchair_accessible_parking')}value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
                <div style={{ display: 'flex' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '18px', lineHeight: '2.5rem', minWidth: '350px' }}>Has wheelchair accessible entrance</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="wheelchairaccessibleentrance"
                  >
                    <FormControlLabel checked={displayAccessibility('has_wheelchair_accessible_entrance')} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel checked={!displayAccessibility('has_wheelchair_accessible_entrance')} value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
                <div style={{ display: 'flex' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '18px', lineHeight: '2.5rem', minWidth: '350px' }}>Has wheelchair accessible seating</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="wheelchairaccessibleseating"
                  >
                    <FormControlLabel checked={displayAccessibility('has_wheelchair_accessible_seating')} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel checked={!displayAccessibility('has_wheelchair_accessible_seating')}value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
                <div style={{ display: 'flex' }}>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontSize: '18px', lineHeight: '2.5rem', minWidth: '350px' }}>Has wheelchair accessible restroom</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="wheelchairaccessibletoilet"
                  >
                    <FormControlLabel checked={displayAccessibility('has_wheelchair_accessible_restroom')} value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel checked={!displayAccessibility('has_wheelchair_accessible_restroom')} value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </div>
        }
        
      </div>
      
        
      </div>
    </div>
  )
}

export default BusinessInfo;
