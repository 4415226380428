import * as React from 'react';
import Sidebar from 'components/Sidebar'
import {Typography , Box, Checkbox, ListItemText, OutlinedInput, Button, Backdrop, CircularProgress, Snackbar, Alert, AlertColor } from '@mui/material';
import Grid from '@mui/material/Grid';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem } from '@mui/material';

import '../components/style.css'
import { useState } from 'react';
import { ApiService } from 'data/ApiService';
import { RepositoryImpl } from 'data/RepositoryImpl';

const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function LocationsAssign(props: any) {
  const loggedInUser = RepositoryImpl((state) => state.loggedInUser)
  const [loading, setLoading] = useState<boolean>(false)
  const [allUsers, setAllUsers] = useState<any[]>([])
  const [refLocations, setRefLocations] = useState<any[]>([])
  const [refGroups, setRefGroups] = useState<any[]>([])
  const [selectedLocationNames, setSelectedLocationNames] = useState<string[]>([])
  const [selectedGroupNames, setSelectedGroupNames] = useState<string[]>([])
  const [selectedUser, setSelectedUser] = useState<string[]>([])
  const [selectedGroupUser, setSelectedGroupUser] = useState<string[]>([])
  const [message, setMessage] = React.useState({
    open: false,
    type: 'success' as AlertColor,
    content: ''
  });
  
  const handleChange = (event: SelectChangeEvent<typeof selectedLocationNames>) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 1000) {
      if (value.includes('all')) {
        if (value.length === Object.entries(refLocations).length + 1) {
          setSelectedLocationNames([])
        } else {
          setSelectedLocationNames(Object.entries(refLocations).map(([key, location]) => location.name))
        }
      } else {
        setSelectedLocationNames(
          typeof value === 'string' ? [] : value.filter((val) => val !== 'all'),
        );
      }
    }
  }

  const handleChangeGroup = (event: SelectChangeEvent<typeof selectedGroupNames>) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 1000) {
      if (value.includes('all')) {
        if (value.length === Object.entries(refGroups).length + 1) {
          setSelectedGroupNames([])
        } else {
          setSelectedGroupNames(Object.entries(refGroups).map(([key, acc]) => acc.accountName))
        }
      } else {
        setSelectedGroupNames(
          typeof value === 'string' ? [] : value.filter((val) => val !== 'all'),
        );
      }
    }
  }

  const handleUserChange = (event: SelectChangeEvent<typeof selectedUser>) => {
    const {
      target: { value },
    } = event;
    let sel: string[] = [];
    sel.push(value as string)
    setSelectedUser(sel);
  }

  const handleUserGroupChange = (event: SelectChangeEvent<typeof selectedGroupUser>) => {
    const {
      target: { value },
    } = event;
    let sel: string[] = [];
    sel.push(value as string)
    setSelectedGroupUser(sel);
  }

  const render = (selectedNames: string[]): string => {
    return selectedNames.map(selectedName => selectedName).join(", ")
  }

  const renderGroup = (selectedNames: string[]): string => {
    return selectedNames.map(selectedName => selectedName).join(", ")
  }

  const renderUserDropdown = (selectedUsers: string[]): string => {
    return selectedUsers.map(selectedUser => selectedUser).join(", ")
  }

  const renderUserGroupDropdown = (selectedUsers: string[]): string => {
    return selectedUsers.map(selectedUser => selectedUser).join(", ")
  }

  const handleAssignLocation = () => {
    console.log('selectedUser::', selectedUser)
    console.log('selectedLocationNames::', selectedLocationNames)
    if (loggedInUser) {
      let locations: any[] = []
      selectedLocationNames.forEach((val) => {
        let location = refLocations.find(g => g.name == val)
        console.log('found location::', location)
        locations.push(location)
      })
      ApiService.assignUserLocationGroup(selectedGroupUser[0], locations, [], loggedInUser.accessToken).then(res => {
        console.log(res)
        setMessage({
          open: true,
          type: 'success' as AlertColor,
          content: 'Assign locations to user successful.'
        })
      })
    }
  }

  const handleAssignGroup = () => {
    console.log('selectedGroupUser::', selectedGroupUser)
    console.log('selectedGroupNames::', selectedGroupNames)
    if (loggedInUser) {
      let groups: any[] = []
      selectedGroupNames.forEach((val) => {
        let group = refGroups.find(g => g.accountName == val)
        groups.push(group)
      })
      ApiService.assignUserLocationGroup(selectedGroupUser[0], [], groups, loggedInUser.accessToken).then(res => {
        console.log(res)
        setMessage({
          open: true,
          type: 'success' as AlertColor,
          content: 'Assign groups to user successful.'
        })
      })
    }
  }

  React.useEffect(() => {
    if (loggedInUser) {
      setLoading(true)
      ApiService.getAllRefLocations(loggedInUser.accessToken).then(res => {
        setRefLocations(res.locations)
      })
      ApiService.getAllRefGroups(loggedInUser.accessToken).then(res => {
        setRefGroups(res.accounts)
      })
      ApiService.getUsers(loggedInUser.accessToken).then(res => {
        setAllUsers(res.users)
      })
    }
  }, []);

  React.useEffect(() => {
    if (refLocations.length > 0 && allUsers.length > 0) {
      setLoading(false)
    }
  }, [refLocations, allUsers]);

  return (
  <div className="container">
    <Backdrop
        sx={{
          zIndex: 20
        }}
        open={loading}
      >
        <CircularProgress color='inherit' />
    </Backdrop>
    <Sidebar />
    <div className="content-area">
      <Snackbar
          open={message.open}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={() => setMessage({ ...message, open: false })}
        >
          <Alert severity={message.type}>{message.content}</Alert>
      </Snackbar>
      <div className="hero">
        <Typography paddingLeft='20px' paddingTop='10px' variant="h1" component="div" align="left">
          Locations Assign
        </Typography>
        
        <Typography paddingLeft='20px' paddingTop='10px' variant="h4" component="div" align="left">
          Assign locations to a specific user here
        </Typography><br/>
        <br/>

        <Box
          sx={{
            width: '90%',
            backgroundColor: 'var(--yin_bg)',
            borderRadius: 2,
            boxShadow: 2,
            marginLeft: '20px',
            padding: '20px'
          }}
        >
          <Grid container spacing={3} paddingLeft="20px" justifyContent="bottom">
            <Grid item xs={4}>
              <FormControl sx={{ my: 1, mx: { xs: 0, lg: 1 }, width: '100%', maxWidth: '400px' }}>
                <InputLabel id="location-multiple-checkbox-label">User</InputLabel>
                <Select
                  labelId="location-multiple-checkbox-label"
                  id="location-multiple-checkbox"
                  value={selectedUser}
                  onChange={handleUserChange}
                  input={<OutlinedInput label="User" />}
                  renderValue={renderUserDropdown}
                  MenuProps={MenuProps}
                >
                  {Object.entries(allUsers).map(([key, user]) => (
                    <MenuItem key={key} value={user.email} sx={{ alignItems: 'start' }}>
                      <Checkbox sx={{ padding: '4px 10px 4px 0' }} checked={selectedUser == user.email} />
                      {/* @ts-ignore */}
                      <Box component='div'>
                        <ListItemText primary={user.email} />
                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14 }}>
                            {`${user.firstName} ${user.lastName}`}
                          </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ my: 1, mx: { xs: 0, lg: 1 }, width: '100%', maxWidth: '400px' }}>
                <InputLabel id="location-multiple-checkbox-label">Location</InputLabel>
                <Select
                  labelId="location-multiple-checkbox-label"
                  id="location-multiple-checkbox"
                  multiple
                  value={selectedLocationNames}
                  onChange={handleChange}
                  input={<OutlinedInput label="Location" />}
                  renderValue={render}
                  MenuProps={MenuProps}
                >
                  <MenuItem value='all'>
                    <Checkbox
                      sx={{ padding: '4px 10px 4px 0' }}
                      indeterminate={!!selectedLocationNames.length && selectedLocationNames.length !== Object.entries(refLocations).length}
                      checked={selectedLocationNames.length === Object.entries(refLocations).length}
                    />
                    Select All
                  </MenuItem>
                  {Object.entries(refLocations).map(([key, location]) => (
                    <MenuItem key={key} value={location.name} sx={{ alignItems: 'start' }}>
                      <Checkbox sx={{ padding: '4px 10px 4px 0' }} checked={selectedLocationNames.findIndex((selected) => {return selected == location.name}) > -1} />
                      {/* @ts-ignore */}
                      <Box component='div'>
                        <ListItemText primary={location.title} />
                        {location.storefrontAddress && location.storefrontAddress.addressLines.length ? (
                          <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14 }}>
                            {`${location.storefrontAddress.addressLines[0]}, ${location.storefrontAddress.locality}, ${location.storefrontAddress.administrativeArea}, ${location.storefrontAddress.postalCode}`}
                          </Typography>
                        ) : null}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} >
              <Button sx={{ my: 1, mx: { xs: 0, lg: 1 }, width: '100%', height: '-webkit-fill-available', maxWidth: '400px' }} variant="contained" onClick={handleAssignLocation}>Assign Location</Button>
            </Grid>
          </Grid>

          {/* Groups assignment */}
          <Grid container spacing={3} paddingLeft="20px" justifyContent="bottom">
            <Grid item xs={4}>
              <FormControl sx={{ my: 1, mx: { xs: 0, lg: 1 }, width: '100%', maxWidth: '400px' }}>
                <InputLabel id="location-multiple-checkbox-label">User</InputLabel>
                <Select
                  labelId="location-multiple-checkbox-label"
                  id="location-multiple-checkbox"
                  value={selectedGroupUser}
                  onChange={handleUserGroupChange}
                  input={<OutlinedInput label="User" />}
                  renderValue={renderUserGroupDropdown}
                  MenuProps={MenuProps}
                >
                  {Object.entries(allUsers).map(([key, user]) => (
                    <MenuItem key={key} value={user.email} sx={{ alignItems: 'start' }}>
                      <Checkbox sx={{ padding: '4px 10px 4px 0' }} checked={selectedGroupUser == user.email} />
                      {/* @ts-ignore */}
                      <Box component='div'>
                        <ListItemText primary={user.email} />
                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14 }}>
                            {`${user.firstName} ${user.lastName}`}
                          </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ my: 1, mx: { xs: 0, lg: 1 }, width: '100%', maxWidth: '400px' }}>
                <InputLabel id="location-multiple-checkbox-label">Group</InputLabel>
                <Select
                  labelId="location-multiple-checkbox-label"
                  id="location-multiple-checkbox"
                  multiple
                  value={selectedGroupNames}
                  onChange={handleChangeGroup}
                  input={<OutlinedInput label="Groups" />}
                  renderValue={renderGroup}
                  MenuProps={MenuProps}
                >
                  <MenuItem value='all'>
                    <Checkbox
                      sx={{ padding: '4px 10px 4px 0' }}
                      indeterminate={!!selectedGroupNames.length && selectedGroupNames.length !== Object.entries(refGroups).length}
                      checked={selectedGroupNames.length === Object.entries(refGroups).length}
                    />
                    Select All
                  </MenuItem>
                  {Object.entries(refGroups).map(([key, acc]) => (
                    <MenuItem key={key} value={acc.accountName} sx={{ alignItems: 'start' }}>
                      <Checkbox sx={{ padding: '4px 10px 4px 0' }} checked={selectedGroupNames.findIndex((selected) => {return selected == acc.accountName}) > -1} />
                      {/* @ts-ignore */}
                      <Box component='div'>
                        <ListItemText primary={acc.accountName} />
                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14 }}>
                            {`${acc.accountName}`}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} >
              <Button sx={{ my: 1, mx: { xs: 0, lg: 1 }, width: '100%', height: '-webkit-fill-available', maxWidth: '400px' }} variant="contained" onClick={handleAssignGroup}>Assign Group</Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
    </div>
  )
}

export default LocationsAssign;
