import * as React from 'react';
import Sidebar from 'components/Sidebar'
import {Typography , Box} from '@mui/material';
import Grid from '@mui/material/Grid';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import Header from 'components/Header'

import '../components/style.css'
import OpenCloseSwitch from 'components/OpenCloseSwitch';

function HoursOfOperation(props: any) {
  return (
  <div className="container">
    <Sidebar />
    <div className="content-area">
      <Header />
      <div className="hero">
        <Typography paddingLeft='20px' paddingTop='10px' variant="h1" component="div" align="left">
          Hours of Operation
        </Typography>
        
        <Typography paddingLeft='20px' paddingTop='10px' variant="h4" component="div" align="left">
          Change your business hours here
        </Typography><br/>
        <br/>

        <Box
          sx={{
            width: '60%',
            backgroundColor: 'var(--yin_bg)',
            borderRadius: 2,
            boxShadow: 2,
            marginLeft: '20px',
            padding: '20px'
          }}
        >
          <Grid container spacing={3} paddingLeft="20px" justifyContent="bottom">
            <Grid 
              item xs={3}           
              sx={{
                marginTop: '6px',
              }}
            >
              <Typography variant="h4" component="div" align="left">Monday</Typography>
            </Grid>
            <Grid item xs={3} >
                <OpenCloseSwitch/>
            </Grid>
            <Grid item xs={3}>
              <TimeSelect title='open time' />
            </Grid>
            <Grid item xs={3}>
              <TimeSelect title= 'close time'/>
            </Grid>


            <Grid 
              item xs={3}           
              sx={{
                marginTop: '6px',
              }}
            >
              <Typography variant="h4" component="div" align="left">Tuesday</Typography>
            </Grid>
            <Grid item xs={3}>
              <OpenCloseSwitch/>
            </Grid>
            <Grid item xs={3}>
              <TimeSelect title='open time' />
            </Grid>
            <Grid item xs={3}>
              <TimeSelect title= 'close time'/>
            </Grid>


            <Grid 
              item xs={3}           
              sx={{
                marginTop: '6px',
              }}
            >
              <Typography variant="h4" component="div" align="left">Wednesday</Typography>
            </Grid>
            <Grid item xs={3}>
              <OpenCloseSwitch/>
            </Grid>
            <Grid item xs={3}>
              <TimeSelect title='open time' />
            </Grid>
            <Grid item xs={3}>
              <TimeSelect title= 'close time'/>
            </Grid>


            <Grid 
              item xs={3}           
              sx={{
                marginTop: '6px',
              }}
            >
              <Typography variant="h4" component="div" align="left">Thursday</Typography>
            </Grid>
            <Grid item xs={3}>
              <OpenCloseSwitch/>
            </Grid>
            <Grid item xs={3}>
              <TimeSelect title='open time' />
            </Grid>
            <Grid item xs={3}>
              <TimeSelect title= 'close time'/>
            </Grid>


            <Grid 
              item xs={3}           
              sx={{
                marginTop: '6px',
              }}
            >
              <Typography variant="h4" component="div" align="left">Friday</Typography>
            </Grid>
            <Grid item xs={3}>
              <OpenCloseSwitch/>
            </Grid>
            <Grid item xs={3}>
              <TimeSelect title='open time' />
            </Grid>
            <Grid item xs={3}>
              <TimeSelect title= 'close time'/>
            </Grid>


            <Grid 
              item xs={3}           
              sx={{
                marginTop: '6px',
              }}
            >
              <Typography variant="h4" component="div" align="left">Saturday</Typography>
            </Grid>
            <Grid item xs={3}>
              <OpenCloseSwitch/>
            </Grid>
            <Grid item xs={3}>
              <TimeSelect title='open time' />
            </Grid>
            <Grid item xs={3}>
              <TimeSelect title= 'close time'/>
            </Grid>


            <Grid 
              item xs={3}           
              sx={{
                marginTop: '6px',
              }}
            >
              <Typography variant="h4" component="div" align="left">Sunday</Typography>
            </Grid>
            <Grid item xs={3}>
              <OpenCloseSwitch/>
            </Grid>
            <Grid item xs={3}>
              <TimeSelect title='open time' />
            </Grid>
            <Grid item xs={3}>
              <TimeSelect title= 'close time'/>
            </Grid>

          </Grid>
        </Box>
      </div>
    </div>
    </div>
  )
}

function TimeSelect(props: any) {
  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{props.title}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Time"
          onChange={handleChange}
          size='small'
        >
          <MenuItem value={10}>9 AM</MenuItem>
          <MenuItem value={20}>10 AM</MenuItem>
          <MenuItem value={30}>11 AM</MenuItem>
        </Select>
      </FormControl>
  );
}

export default HoursOfOperation;
