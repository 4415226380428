import * as React from 'react';
import '../components/style.css'
import Sidebar from 'components/Sidebar'
import { Box, Button, Typography } from '@mui/material';
import Header from 'components/Header'
import {CurrentPinPuller, MapWithMarker} from 'components/MapWithMarker';
import { RepositoryImpl } from 'data/RepositoryImpl';

export default function PlacePin(props: any) {
  const selectedLocations = RepositoryImpl((state) => state.selectedLocations);
  const locations = RepositoryImpl((state) => state.locations);
  const updatePin = RepositoryImpl((state) => state.updatePin);

  var markedLocation = null as any
  if (selectedLocations.length > 0) {
    markedLocation = locations[selectedLocations[0]];
  }

  const ref = React.useRef<CurrentPinPuller>(null);
  const updatePinForLocation = () => {
    if (ref.current && markedLocation !== null) {
      updatePin(markedLocation.name, ref.current.pullLocation());
    }
  };

  const resetPinLocation = () => {
    if (ref.current && markedLocation !== null) {
      ref.current.reset({
        lattitude: markedLocation.latlng?.latitude,
        longitude: markedLocation.latlng?.longitude
      })
    }
  }

  return (
    <div className="container">
      <Sidebar />
      <div className="content-area">
        <Header />
        <div className="hero">
          <Typography paddingLeft='20px' paddingTop='10px' variant="h1" component="div" align="left">
            Place Maps Pin
          </Typography>
          
          <Typography paddingLeft='20px' paddingTop='10px' variant="body1" component="div" align="left">
            Make sure to point your customers to your front door.<br/> GPS, Walking, Biking, Transit and Ride Sharing services use your Maps pin to navigate to your location.
          </Typography><br/>

          { 
            markedLocation !== null &&
            <Typography paddingLeft='20px' paddingTop='10px' variant="body1" component="div" align="left">
              <strong>Location:&nbsp; {markedLocation.title}</strong>
            </Typography>
          }
          <br/>
          <Box
            sx={{
              width: 600,
              height: 400,
              marginLeft: 2
            }}
          >
            <MapWithMarker 
              markerRef = {ref}
              showMarker={markedLocation !== null}
              markerLattitude={markedLocation? markedLocation.latlng?.latitude: 0} 
              markerLongitude={markedLocation? markedLocation.latlng?.longitude: 0}
            />
            <br/><br/>
            <Button onClick={updatePinForLocation} variant="contained">Update</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={resetPinLocation} variant="outlined">Reset Pin</Button>
          </Box>
          <br/><br/>
        </div>
      </div>
    </div>
  )
}
