import "./style.css";

import * as React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { PinLocation } from "domain/Repository";

interface CurrentPinPuller {
  pullLocation: () => PinLocation
  reset: (loc: PinLocation) => void
}

interface MapWithMarkerProps {
  showMarker: boolean
  markerLattitude: number
  markerLongitude: number
  markerRef: React.Ref<CurrentPinPuller>
}

function MapWithMarker(props: MapWithMarkerProps) {
  const center = {
    lat: props.markerLattitude,
    lng: props.markerLongitude
  }

  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };

  return (
    <Wrapper apiKey={"AIzaSyCXyHqMs3eOd-bufoZW8qpGkN0Vqc_sqFo"} render={render}>
      <Map
        center={center}
        zoom={16}
        style={{ flexGrow: "1", height: "100%" }}
      >
        {
          props.showMarker && 
          <Marker draggable={true} position={center} ref={props.markerRef} />
        }

      </Map>
    </Wrapper>
  );
};

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
}

const Map: React.FC<MapProps> = ({
  children,
  style,
  ...options
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, options));
    }
    map?.setOptions(options)
  }, [ref, map, options]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

interface MarkerProps extends google.maps.MarkerOptions {
  onPinChange?: (newLocation: PinLocation) => void 
}

const Marker = React.forwardRef((props: MarkerProps, ref: React.Ref<CurrentPinPuller>) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useImperativeHandle(ref, () => ({ pullLocation, reset }));

  const pullLocation = () => { 
    var lat = marker?.getPosition()?.lat()
    if (!lat) {
      lat = 0
    }

    var lng = marker?.getPosition()?.lng()
    if (!lng) {
      lng = 0
    }
    return {
      lattitude: lat,
      longitude: lng
    }
  }

  const reset = (toLocation: PinLocation) => {
    marker?.setPosition({
      lat: toLocation.lattitude,
      lng: toLocation.longitude
    })
  }

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(props);
    }
  }, [marker, props]);
  return null;
});

export type { PinLocation, CurrentPinPuller, MapWithMarkerProps }
export {MapWithMarker}
