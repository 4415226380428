import { UploadableFile } from "components/MediaUploader";
import type { User } from "domain/User";
import { Account, AccountByAccountName, LocationNamesByAccountName } from "domain/Account";

function dateToday(): string {
  var today = new Date();
  today.setHours(0, 0, 0, 0);
  console.log("DateToday ******", today.toISOString())
  return today.toISOString();
}

function dateLast30Day(): string {
  var today = new Date();
  var lastMonth = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
  lastMonth.setHours(0, 0, 0, 0);
  console.log("Date Last Month ******", lastMonth.toISOString())
  return lastMonth.toISOString();
}

function dateLast400Day(): string {
  var today = new Date();
  var last = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 400);
  last.setHours(0, 0, 0, 0);
  console.log("Date Last 400 ******", last.toISOString())
  return last.toISOString();
}

interface PinLocation {
  lattitude: number
  longitude: number
}

interface LocationInsight {
  directionRequests: number
  phoneCalls: number
  websiteVisits: number
  directQueriesDesktop: number
  indirectQueriesDesktop: number
  directQueriesMobile: number
  indirectQueriesMobile: number
}

interface EventFormData {
  title: string
  startDate: Date
  endDate: Date
  details: string | null
  buttonType: string | null
  buttonLink: string | null
  uploadedFiles: UploadableFile[]
}

interface Repository {
  // State
  loading: boolean
  loggedInUser: User | undefined
  accounts: AccountByAccountName
  locationNamesByAccountName: LocationNamesByAccountName
  locations: {[key: string]: any}
  insightByLocation: {[key: string]: LocationInsight}
  insightByLocationLastMonth: {[key: string]: LocationInsight}
  insightByLocationLastYear: {[key: string]: LocationInsight}
  insightCumulative: LocationInsight
  insightCumulativeLastMonth: LocationInsight
  insightCumulativeLastYear: LocationInsight
  monthlyChange: LocationInsight
  yearlyChange: LocationInsight
  selectedAccount: Account | undefined
  selectedLocations: string[]
  selectedDateRange: [string, string]
  averageTransactionValue: number
  
  loggedIn(name: string, email: string, accessToken: string, role?: string): Promise<void>
  logout(): Promise<void>
  updateToken(token: string): void
  setSelectedAccount(accountName: string): Promise<void>
  setSelectedLocations(locationsNames: string[]): void
  setSelectedDateRange(newRange: [string, string]): void
  fetchInsights(): Promise<any>
  postEvent(data: EventFormData): Promise<any>
  updatePin(locationName: string, coordinates: PinLocation): Promise<void>
  getAverageTransactionValue(): Promise<any>
  updateAverageTransactionValue(newAverageTransactionValue: number): Promise<void>
}

export type { PinLocation, LocationInsight, Repository , EventFormData};
export { dateToday, dateLast30Day, dateLast400Day};
