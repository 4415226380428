const BaseURL = process.env.REACT_APP_API_BASE_URL

export class ApiService {
  static async login(credentials: { email: string, password: string }) {
    const url =`${BaseURL}/auth/login`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials)
      })
      const json = await response.json()
      if (String(response.status).charAt(0) === '4') {
        return { error: json }
      }
      return json
  }

  static async register(data: { email: string, password: string }) {
    const url =`${BaseURL}/auth/register`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      const json = await response.json()
      if (String(response.status).charAt(0) === '4') {
        return { error: json }
      }
      return json
  }

  static async validateToken(token: string) {
    const url =`${BaseURL}/auth/validate`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      return response.status === 200
  }

  static async getUsers(token: string) {
    const url =`${BaseURL}/auth/users`
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    const json = await response.json()
    return json
  }

  static async getUserLocations(token: string, username: string) {
    const url =`${BaseURL}/locations/${username}`
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    const json = await response.json()
    return json
  }

  static async insertRefLocations(token: string) {
    const url =`${BaseURL}/locations/init`
    let jsonData = require('./ref-locations.json');
    console.log(jsonData);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(jsonData)
    })
    const json = await response.json()
    return json
  }

  static async insertRefAccounts(token: string) {
    const url =`${BaseURL}/accounts/init`
    let jsonData = require('./ref-accounts.json');
    console.log(jsonData);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(jsonData)
    })
    const json = await response.json()
    return json
  }

  static async getAllRefLocations(token: string) {
    const url =`${BaseURL}/locations/list`
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    })
    const json = await response.json()
    return json
  }

  static async getAllRefGroups(token: string) {
    const url =`${BaseURL}/accounts/list`
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    })
    const json = await response.json()
    return json
  }

  static async assignUserLocationGroup(user: string, locations: any[], groups: any[], token: string) {
    const url =`${BaseURL}/accounts/assign`
    const jsonData = {
      user,
      locations,
      groups
    }
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(jsonData)
    })
    const json = await response.json()
    return json
  }

  static async assignUserLocationManual(token: string) {
    const url =`${BaseURL}/locations/user/assign-manual`
    let jsonData = require('./ref-user-locations.json');
    console.log(jsonData);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(jsonData)
    })
    const json = await response.json()
    return json
  }
}
