const BaseURL = process.env.REACT_APP_IMGBB_API_URL
const IMGBB_KEY = process.env.REACT_APP_IMGBB_KEY

export class ImgbbService {

    static async upload(file: any) {
        const url = `${BaseURL}/1/upload?key=${IMGBB_KEY}&expiration=600`
        const formData = new FormData();
        formData.append('image', file)
        const response = await fetch(url, {
            method: 'POST',
            body: formData
        })
        const json = await response.json()
        return json
    }

}