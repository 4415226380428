import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface DonutProps {
  directQueriesDesktop: number
  indirectQueriesDesktop: number
  directQueriesMobile: number
  indirectQueriesMobile: number
}

export default function DonutChart(props: DonutProps) {
  const data = {
    labels: ['Desktop from Search', 'Desktop from Maps', 'Mobile from Search', 'Mobile from Maps'],
    datasets: [
      {
        data: [props.directQueriesDesktop, props.indirectQueriesDesktop, props.directQueriesMobile, props.indirectQueriesMobile],
        backgroundColor: [
          '#7F56D9', '#2BD7B0', 'rgb(255, 21, 22)', 'rgb(251, 192, 45)'
        ],
      },
    ],
  };



  ChartJS.register({
    id: 'textRender',
    beforeDatasetDraw: function(chart: ChartJS) {
      const width = chart.width
      const height = chart.height
      var ctx = chart.ctx;
      var directDesktop = chart.data.datasets[0]?.data[0] as number
      var indirectDesktop = chart.data.datasets[0]?.data[1] as number
      var directMobile = chart.data.datasets[0]?.data[2] as number
      var indirectMobile = chart.data.datasets[0]?.data[3] as number

      ctx.restore();
      if (!directDesktop || !indirectDesktop || !directMobile || !indirectMobile) {
        return true
      }

      if (isNaN(directDesktop!) || isNaN(indirectDesktop!) || isNaN(directMobile!) || isNaN(indirectMobile!)) {
        return true
      }

      if ((directDesktop + indirectDesktop + directMobile +  indirectMobile) === 0) {
        return true
      }

      var labelFontSize = (height / 250).toFixed(2);
      var textFontSize = (height / 150).toFixed(2);
      var percFontSize = (height / 200).toFixed(2);
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      
      var label = ""
      var text = ""
      var percent = ""

      if (chart.getActiveElements().length === 0) {
        label = "All Searches"
        text = (directDesktop! + indirectDesktop! + directMobile! + indirectMobile!).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        percent = ""
      } else {
        const index = chart.getActiveElements().at(0)!.index
        label = data.labels[index]
        var selected = chart.data.datasets[0]!.data[index]! as number
        text = selected.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        percent = ((100.0 * selected) / (directDesktop! + indirectDesktop! + directMobile! + indirectMobile!)).toFixed(2) + " %"
      }

      var labelX = Math.round(width/2)
      if (label.length > 0) {
        // var labelX = Math.round((width - ctx.measureText(label!).width) / 2)
        var labelY = height / 3 ;  
        ctx.font = labelFontSize + "em Hellix-Regular";
        ctx.fillText(label, labelX, labelY);
      }

      if(text.length > 0 ) {
        var textX = labelX
        var textY = height / 2 ;  
        ctx.font = textFontSize + "em Hellix-Regular";
        ctx.fillText(text, textX, textY);
      }

      if (percent.length > 0) { 
        var percX = labelX
        var percY = height / 1.5 ;  
        ctx.font = percFontSize + "em Hellix-Regular";
        ctx.fillText(percent, percX, percY);
      }
    }
  });

  return <Doughnut 
    data={data}   
    options={{
      cutout: '70%',
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        tooltip: {
          enabled: false
        },
        legend: {
          display: false
        }
      }
    }}
    />;
}

