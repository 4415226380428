import * as React from 'react';
import '../components/style.css'
import { Box, Button, Typography } from '@mui/material';
import { PleperApiService } from 'data/PleperApiService';
import { useState } from 'react';

export default function PleperMapSearch(props: any) {
  const [keyword, setKeyword] = useState<string>('')
  const [fileList, setFileList] = useState<any>([])
  const [status, setStatus] = useState<string>('')
  const [errorCode, setErrorCode] = useState<number>(0)
  const [batchJson, setBatchJson] = useState<any>()

  React.useEffect(() => {
    setFileList([
      {name: 'us_cities_1.json', status: ''}, 
      {name: 'us_cities_2.json', status: ''}, 
      {name: 'us_cities_3.json', status: ''}, 
      {name: 'us_cities_4.json', status: ''}, 
      {name: 'us_cities_5.json', status: ''}, 
      {name: 'us_cities_6.json', status: ''}, 
      {name: 'us_cities_7.json', status: ''},
      {name: 'us_cities_8.json', status: ''}
    ])
  }, [])

  const runExport = (file: any) => {
    setStatus('Running')
    console.log('keyword::', keyword)
    console.log('Running file::', file)
    if (!keyword) {
      alert('Input keyword');
      return
    }
    let temp = JSON.parse(JSON.stringify(fileList))
    let selectedFile = temp.find((file1: any) => file1.name == file.name)
    selectedFile.status = 'Running'
    setFileList(temp);
    PleperApiService.mapSearch(file.name, keyword).then(res => {
      console.log('mapSearch result::', res)
      let total = res?.length;
      setBatchJson(res)
      setTimeout(function() {
          PleperApiService.getBatchResults(res || [], 'google/search/maps').then(data => {
            var csv = ConvertToCSV(data);
            var header = 'name,phone_number,address,country_code,postalCode,city,latitude,longitude,categories,categories_gcid,business_profile_id,place_id,cid,verified,website\r\n'
            csv = header + csv;
            var downloadLink = document.createElement("a");
            var blob = new Blob(["\ufeff", csv]);
            var url = URL.createObjectURL(blob);
            downloadLink.href = url;
            downloadLink.download = "data.csv";
        
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            setStatus('Finished')
            selectedFile.status = `Finished. Total records: ${total}`
            setFileList(temp);
          }).catch(err => {
            setStatus('Failed when get batch results...');
            selectedFile.status = 'Failed'
            setFileList(temp);
            setErrorCode(2);
          })
      }, 15000);
    }).catch(err=> {
      setStatus('Failed when calling map search api...');
      selectedFile.status = 'Failed'
      setFileList(temp);
      setErrorCode(1);
    })
  }

  const onChangeKeyword = (e: any) => {
    let val = e.target.value
    console.log('keyword::', val)
    setKeyword(val)
  }

  function ConvertToCSV(objArray: any) {
    let headers = ['name','phone_number','address','country_code','postalCode','city','latitude','longitude','categories','categories_gcid','business_profile_id','place_id', 'cid', 'verified', 'website']
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index of headers) {
          if (line != '') line += ','
          let temp = Array.isArray(array[i][index]) ? '"' + array[i][index].join() + '"' : (!array[i][index] || typeof array[i][index] === "undefined" ? ' ': '"' + array[i][index] + '"')
          line += temp;
        }

        str += line + '\r\n';
    }

    return str;
  }

  return (
    <div>
      <div className="content-area">
        <Typography paddingLeft='20px' paddingTop='10px' variant="h1" component="div" align="left">
          Pleper Map Search
        </Typography><br/>
        <input type='text' onChange={(evt) => onChangeKeyword(evt)}></input><br/>
        <table id='pleperScriptTable'>
            <thead>
              <tr>
                <td>Script name</td>
                <td>Status</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {
                fileList.map((file: any) => {
                  return <tr key={file.name}>
                    <td>{file.name}</td>
                    <td>{file.status}</td>
                    <td><button onClick={() => runExport(file)}>Export</button></td>
                  </tr>
                })
              }
            </tbody>
          </table>
      </div>
    </div>
  )
}
