import * as React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Performance from "pages/Performance";
import LoginForm from "components/LoginForm";
import RegisterForm from "components/RegisterForm";
import Promos from "pages/Promos";
import LocationInfo from "pages/LocationInfo";
import BusinessInfo from "pages/BusinessInfo";
import PlacePin from "pages/PlacePin";
import Settings from "pages/Settings";
import Support from "pages/Support";
import HoursOfOperation from "pages/HoursOfOperation";
import Media from "pages/Media";
import { RepositoryImpl } from 'data/RepositoryImpl';
import { GoogleApiService } from "data/GoogleApiService";
import { User } from "domain/User";
import { ApiService } from "data/ApiService";
import LocationsAssign from "pages/LocationsAssign";
import PleperMapSearch from "pages/PleperMapSearch";

const theme = createTheme({
  typography: {
    fontFamily: 'Hellix-Regular',
    h1: {
      fontFamily: 'Hellix-ExtraBold',
      fontSize: 40,
    },
    h2: {
      fontFamily: 'Hellix-Bold',
      fontSize: 26,
    },
    h3: {
      fontFamily: 'Hellix-Medium',
      fontSize: 32,
    },    
    h4: {
      fontFamily: 'Hellix-Medium',
      fontSize: 20,
    },
    body1: {
      fontFamily: 'Hellix-Regular',
      fontSize: 16,
    },
    body2: {
      fontFamily: 'Hellix-Regular',
      fontSize: 14,
    },
    button: {
      fontFamily: 'Hellix-Medium',
      fontSize: 16,
    },
    caption: {
      fontFamily: 'Hellix-Regular',
    },
  },
  palette: {
    primary: {
      main: '#6033FA',
    },
  },
});

const ProtectedRoute = ({ user, children }: { user: User | undefined, children: React.ReactElement }) => {
  if (user) {
    return children;
  }
  return <Navigate to="/login" />
};

export default function App() {
  const loggedInUser = RepositoryImpl(state => state.loggedInUser);
  const logout = RepositoryImpl(state => state.logout);

  React.useEffect(() => {
    const validateToken = async (role: string, token: string) => {
      let result;
      if (role === 'member') {
        result = await ApiService.validateToken(token);
      } else {
        result = await GoogleApiService.validateToken(token);
      }
      if (!result) {
        logout();
      }
    };

    if (loggedInUser && loggedInUser.accessToken) {
      validateToken(loggedInUser.role, loggedInUser.accessToken);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/" element={
          <ProtectedRoute user={loggedInUser}>
            <div>Home page</div>
          </ProtectedRoute>
        } />
        <Route path="/performance" element={
          <ProtectedRoute user={loggedInUser}>
            <Performance />
          </ProtectedRoute>
        } />
        <Route path="/promos" element={
          <ProtectedRoute user={loggedInUser}>
            <Promos />
          </ProtectedRoute>
        } />
        <Route path="/location" element={
          <ProtectedRoute user={loggedInUser}>
            <LocationInfo />
          </ProtectedRoute>
        } />
        <Route path="/business" element={
          <ProtectedRoute user={loggedInUser}>
            <BusinessInfo />
          </ProtectedRoute>
        } />
        <Route path="/hours" element={
          <ProtectedRoute user={loggedInUser}>
            <HoursOfOperation />
          </ProtectedRoute>
        } />
        <Route path="/pin" element={
          <ProtectedRoute user={loggedInUser}>
            <PlacePin />
          </ProtectedRoute>
        } />
        <Route path="/media" element={
          <ProtectedRoute user={loggedInUser}>
            <Media />
          </ProtectedRoute>
        } />
        <Route path="/support" element={
          <ProtectedRoute user={loggedInUser}>
            <Support />
          </ProtectedRoute>
        } />
        <Route path="/locations-assign" element={
          <ProtectedRoute user={loggedInUser}>
            <LocationsAssign />
          </ProtectedRoute>
        } />
        <Route path="/settings" element={
          <ProtectedRoute user={loggedInUser}>
            <Settings />
          </ProtectedRoute>
        } />
        <Route path="/pleper/map-search" element={
          <ProtectedRoute user={loggedInUser}>
            <PleperMapSearch />
          </ProtectedRoute>
        } />
      </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}
