import { Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Typography
} from '@mui/material';
import { 
  AccessTime, 
  BarChartOutlined, 
  ChatBubbleOutlineOutlined, 
  EditLocationOutlined, 
  LocalOfferOutlined, 
  PermMediaOutlined, 
  StorefrontOutlined, 
  SettingsOutlined,
  AssignmentOutlined
} from '@mui/icons-material';

import CircularProgressWithLabel from 'components/CircularProgressWithLabel';
import { RepositoryImpl } from 'data/RepositoryImpl';

import logo from 'images/logo_contrast.svg';
import logoutIcon from 'images/logout.svg'
import 'components/style.css'

interface SidebarProps {

}

function Sidebar(props: SidebarProps) {
  const user = RepositoryImpl((state) => state.loggedInUser);
  const logout = RepositoryImpl((state) => state.logout);

  return (
    <div className="sidebar">
      <List>
        <ListItem>
          <Box component="img" alt="Mapforce" src={logo} />
        </ListItem>
        <br/>
        <ListItem>
            <ListItemButton component={Link} to="/performance">
              <BarChartOutlined className="sidebar-item-icon"/>
              <span className="sidebar-item-text">
                <Typography variant="body2"> &nbsp;&nbsp;Performance</Typography>
              </span> 
            </ListItemButton>
        </ListItem>
        <ListItem>
            <ListItemButton component={Link} to="/promos">
              <LocalOfferOutlined className="sidebar-item-icon"/>
              <span className="sidebar-item-text">
                <Typography variant="body2"> &nbsp;&nbsp;Promos & Updates</Typography>
              </span> 
            </ListItemButton>
        </ListItem>
        <ListItem>
            <ListItemButton component={Link} to="/business">
              <StorefrontOutlined className="sidebar-item-icon"/>
              <span className="sidebar-item-text">
                <Typography variant="body2"> &nbsp;&nbsp;Business Info</Typography>
              </span>  
            </ListItemButton>
        </ListItem>
        <ListItem>
            <ListItemButton component={Link} to="/hours">
              <AccessTime className="sidebar-item-icon"/>
              <span className="sidebar-item-text">
                <Typography variant="body2"> &nbsp;&nbsp;Hours of Operation</Typography>
              </span> 
            </ListItemButton>
        </ListItem>
        <ListItem>
            <ListItemButton component={Link} to="/pin">
              <EditLocationOutlined className="sidebar-item-icon"/>
              <span className="sidebar-item-text">
                <Typography variant="body2"> &nbsp;&nbsp;Place Maps Pin</Typography>
              </span> 
            </ListItemButton>
        </ListItem>
        <ListItem>
            <ListItemButton component={Link} to="/media">
              <PermMediaOutlined className="sidebar-item-icon"/>
              <span className="sidebar-item-text">
                <Typography variant="body2"> &nbsp;&nbsp;Photos & Videos</Typography>
              </span>  
            </ListItemButton>
        </ListItem>
        <br/> <br/>
        <ListItem>
            <ListItemButton component={Link} to="/support">
              <ChatBubbleOutlineOutlined className="sidebar-item-icon"/>
              <span className="sidebar-item-text">
                <Typography variant="body2"> &nbsp;&nbsp;Support</Typography>
              </span> 
            </ListItemButton>
        </ListItem>
        {
          user?.role == 'admin' && <ListItem>
            <ListItemButton component={Link} to="/locations-assign">
              <AssignmentOutlined className="sidebar-item-icon"/>
              <span className="sidebar-item-text">
                <Typography variant="body2"> &nbsp;&nbsp;Location Assign</Typography>
              </span> 
            </ListItemButton>
          </ListItem>
        }
        <ListItem>
            <ListItemButton component={Link} to="/settings">
              <SettingsOutlined className="sidebar-item-icon"/>
              <span className="sidebar-item-text">
                <Typography variant="body2"> &nbsp;&nbsp;Settings</Typography>
              </span> 
            </ListItemButton>
        </ListItem>

        <Box
          sx={{
            width: 180,
            height: 180,
            backgroundColor: 'var(--yang_faded_bg)',
            marginLeft: 2,
            borderRadius: 2,
            padding: 1
          }}
        >
          <br/>
          &nbsp;&nbsp;<CircularProgressWithLabel style={{color: 'var(--yang_text_lite)'}} value={80} variant="determinate" />
          <br/>
          <br/>
          <span className="sidebar-bubble-text">
            <Typography variant="body2"> 
              Your team has used 80% of all features. Need more?
              <br/><br/>
              Dimiss  Upgrade
            </Typography>
          </span>
        </Box>
        <Divider style={{ background: 'var(--yang_faded_bg)', marginLeft: 8, marginTop: 16, height: 2, borderRadius: 8}} variant="middle" />
      </List>
      <Box
        component='div'
        sx={{ display: 'flex', mt: 'auto', mx: '1rem', mb: '1rem' }}
      >
        <Avatar>Test</Avatar>
        <Box component='div' sx={{ ml: '0.5rem', color: '#FFF' }}>
          <Typography sx={{ my: 0, fontSize: '14px', fontWeight: 500 }}>{user?.name}</Typography>
          <Typography sx={{ my: 0, fontSize: '14px', fontWeight: 400 }}>{user?.email}</Typography>
        </Box>
        <Box
          component='img'
          src={logoutIcon}
          sx={{ ml: 'auto', cursor: 'pointer' }}
          onClick={logout}
        />
      </Box>
    </div>
  );
}

export default Sidebar;
