import { Account, AccountByAccountName } from "domain/Account";
import { dateLast400Day, dateToday, EventFormData, PinLocation } from "domain/Repository";
import {  User } from "domain/User";
import { gzip } from 'pako';

const GOOGLE_BUSINESS_API = 'https://mybusinessbusinessinformation.googleapis.com/v1/'

async function timeout(delay: number) {
  return new Promise( res => setTimeout(res, delay) );
}

export class GoogleApiService {
  static async getCachedDailyMetrics() {
    const url = `${process.env.REACT_APP_API_BASE_URL}/metrics`;
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      console.log("Error reading cached data", response)
    }
    try {
      return response.json()
    } catch(error) {
      console.log("error parsing cache response",error )
      console.log(response)
      return null;
    } 
  }

  static async syncLocationData(user: User) {
    let accountByName = await GoogleApiService.getAccounts(user.accessToken)
    let accounts = Object.values(accountByName)
    let allLocations: any[] = []
    for (var i = 0; i < accounts.length; i++) {
      let account = accounts[i]
      let locs = await GoogleApiService.getLocations(user, account)
      allLocations = allLocations.concat(locs)
    }
    
    console.log("Total Location found across accounts="+allLocations.length.toString())
    let erroredLocations = []
    for (var j=0; j< allLocations.length; j++) {
      await timeout(1000)
      let location = allLocations[j]
      let location_id = location.name.slice(10) // remove prefix 'locations/' from the name
      let start_date = dateLast400Day()
      let end_date = dateToday()
      let result = await this.downloadInsights(user, location.name, start_date, end_date)
      
      if (result == null || result.multiDailyMetricTimeSeries == null) {
        erroredLocations.push({
          location: location_id,
          title: location.title,
        })
        continue
      }

      let toSync = {
        location: location_id,
        start_date: start_date.slice(0, 10),
        end_date: end_date.slice(0, 10),
        multiDailyMetricTimeSeries: result.multiDailyMetricTimeSeries
      }
      const url = `${process.env.REACT_APP_API_BASE_URL}/metrics`;
      await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Encoding': 'gzip',
          'Content-Type': 'application/json',
        },
        body: await gzip(JSON.stringify(toSync)),
      });
    }
    console.log("Errored Locations which could not be synced**********", erroredLocations)
  }

  static refreshTokenSetup(res: any, user: User, updateToken: (token: string) => void) {
    // Timing to renew access token
    let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

    const refreshToken = async (user: User) => {
      const newAuthRes = await res.reloadAuthResponse();
      refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
      console.log('refreshed with newAuthRes:', newAuthRes);
      updateToken(newAuthRes.access_token)
      user.accessToken = newAuthRes.access_token;

      // Setup the other timer after the first one
      setTimeout(refreshToken, refreshTiming, user);
    };

    // Setup first refresh timer
    setTimeout(refreshToken, refreshTiming, user);
  };

  static async getAccounts(accessToken: string): Promise<AccountByAccountName> {
    const gmb_api_version = `${GOOGLE_BUSINESS_API}accounts`;
    const response = await fetch(gmb_api_version, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      },
    });

    const json = await response.json();
    console.log("******** retrived account information *********")
    console.log(json)
    console.log("*********************************************");
    
    let accountsMap = {} as AccountByAccountName
    json.accounts.forEach((item: any) => {
      let account = {
        accountName: item.accountName,
        name: item.name,
        type: item.type,
        verificationState: item.verificationState,
        vettedState: item.vettedState
      } as Account
      
      accountsMap[account.accountName] = account
    });

    return accountsMap;
  }

  static async getLocations(user: User, account: Account): Promise<any> {
    var allLocations = []
    const url = GOOGLE_BUSINESS_API
    + account.name
    + '/locations?pageSize=100'
    + '&readMask=name,title,storefrontAddress,storeCode,regularHours,specialHours,latlng,categories,phoneNumbers,openInfo,metadata'
    
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.accessToken
      },
    });

    const firstPageJson = await response.json();  
    console.log("******** retrived location information *********")
    console.log(firstPageJson);
    console.log("*********************************************");
    allLocations = firstPageJson.locations

    var nextPageToken = firstPageJson.nextPageToken
    while (nextPageToken) {
      await timeout(1000)
      const url = GOOGLE_BUSINESS_API + account.name
      + '/locations?pageSize=100&pageToken='+nextPageToken
      + '&readMask=name,title,storefrontAddress,storeCode,regularHours,specialHours,latlng,categories,phoneNumbers,openInfo,metadata'
      
      const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.accessToken
        },
      });
      const json = await response.json();
      console.log("******** retrived NEXT PAGE location information *********")
      console.log(json);
      console.log("*********************************************");
      nextPageToken = json.nextPageToken 
      allLocations = allLocations.concat(json.locations)
    }
    console.log("Total Locations Retrieved="+allLocations?.length)
    return allLocations;
  }

  static async getLocationDetails(accessToken: string, locationId: string): Promise<any> {
    const url = `${GOOGLE_BUSINESS_API}locations/`
    + locationId
    + '?readMask=name,title,storefrontAddress,storeCode,latlng,phoneNumbers,websiteUri,openInfo,metadata,categories,profile,regularHours,specialHours,moreHours'
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      },
    });

    const json = await response.json();  
    console.log("******** retrived location details information *********")
    console.log(json);
    console.log("*********************************************");

    return json;
  }

  static async getCategoriesList(accessToken: string, filter: string): Promise<any> {
    const url = `${GOOGLE_BUSINESS_API}categories?regionCode=us&languageCode=en&view=FULL` + (filter ? `&filter=displayName=${filter}` : '')
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      },
    });

    const json = await response.json();  
    console.log("******** retrived google categories list *********")
    console.log(json);
    console.log("*********************************************");

    return json;
  }

  static async patchLocationDetails(accessToken: string, locationId: string, updateMask: string, body: any): Promise<any> {
    const url = `${GOOGLE_BUSINESS_API}locations/${locationId}?updateMask=${updateMask}`
    
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      },
      body: JSON.stringify(body)
    });

    const json = await response.json();  
    console.log("******** Finished update location information *********")
    console.log(json);
    console.log("*********************************************");

    return json;
  }

  static async getLocationAttributes(accessToken: string, locationId: string): Promise<any> {
    const url = `${GOOGLE_BUSINESS_API}locations/${locationId}/attributes`
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      },
    });

    const json = await response.json();  
    console.log("******** retrived location details information *********")
    console.log(json);
    console.log("*********************************************");

    return json;
  }

  static async getLocationDetailsAndAttributes(accessToken: string, locationId: string): Promise<any> {
    const [locationInfo, locationAttributes] = await Promise.all([GoogleApiService.getLocationDetails(accessToken, locationId), GoogleApiService.getLocationAttributes(accessToken, locationId)]);
    locationInfo.attributes = locationAttributes.attributes

    console.log("******** retrived location information *********")
    console.log(locationInfo);
    console.log("*********************************************");

    return locationInfo;
  }

  static async prepareUploadMedia(accountId: string, locationId: string, accessToken: string): Promise<any> {
    const url = `https://mybusiness.googleapis.com/v4/accounts/${accountId}/locations/${locationId}/media:startUpload?access_token=${accessToken}`
    const response = await fetch(url, {
      method: 'POST'
    });
    const json = await response.json();
    console.log("******** Preparing to upload media *********")
    console.log(json);
    console.log("*********************************************");
    return json;
  }

  static async startUploadMedia(resourceName: string, accessToken: string, file: any): Promise<any> {
    const url = `https://mybusiness.googleapis.com/upload/v1/media/${resourceName}?upload_type=media&access_token=${accessToken}`
    let formData = new FormData();
    formData.append("file", file);
    try {
      let response = await fetch(url, {
        method: 'POST',
        body: formData
      });
      if (!response.ok) // or check for response.status
        throw new Error(response.statusText);
      console.log("******** Finished upload media *********")
      console.log("*********************************************");
    } catch (err) {
      console.log("******** Error when uploading media *********")
      console.log(err);
    }
  }

  static async linkMediaToBusiness(accountId: string, locationIds: string[], sourceUrl: string, mediaType: string, category: string, accessToken: string): Promise<any> {
    try {
      let linkMediaToLocationsAsync: Promise<any>[] = []
      locationIds.forEach(locationId => {
        linkMediaToLocationsAsync.push(this.linkMediaToLocation(accountId, locationId, sourceUrl, mediaType, category, accessToken))
      })
      const json = await Promise.all(linkMediaToLocationsAsync);
      console.log("******** Finished link media to businesses *********")
      console.log(json);
      console.log("*********************************************");
      return json;
    } catch (err) {
      console.log("******** Error when link media to business *********")
      console.log(err);
    }
  }

  static async linkMediaToLocation(accountId: string, locationId: string, sourceUrl: string, mediaType: string, category: string, accessToken: string): Promise<any> {
    const url = `https://mybusiness.googleapis.com/v4/accounts/${accountId}/locations/${locationId}/media?access_token=${accessToken}`
    let bodyContent = {
      "mediaFormat": mediaType,
      "locationAssociation": {
        "category": category
      },
      "sourceUrl": sourceUrl
    }
    try {
      return await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyContent)
      });
    } catch (err) {
      console.log("******** Error when link media to business *********")
      console.log(err);
    }
  }

  static async getAvgTransactionValue(locationIds: string[]): Promise<any> {
    const url = `${process.env.REACT_APP_API_BASE_URL}/metrics/average-transaction`;
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ locations: locationIds })
    });
    const json =  await response.json();
    return json;
  }

  static async updateAvgTransactionValueWithLocations(locationIds: string[], newValue: number): Promise<any> {
    const url = `${process.env.REACT_APP_API_BASE_URL}/metrics/average-transaction`;
    await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        locations: locationIds,
        newValue
      })
    });
    return;
  }

  static async downloadInsights(user: User, locationName: string, startDate: string, endDate: string): Promise<any> {
    const queryString = new URLSearchParams([
      ["dailyMetrics", "BUSINESS_DIRECTION_REQUESTS"],
      ["dailyMetrics", "CALL_CLICKS"],
      ["dailyMetrics", "WEBSITE_CLICKS"],
      ["dailyMetrics", "BUSINESS_IMPRESSIONS_DESKTOP_MAPS"],
      ["dailyMetrics", "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH"],
      ["dailyMetrics", "BUSINESS_IMPRESSIONS_MOBILE_MAPS"],
      ["dailyMetrics", "BUSINESS_IMPRESSIONS_MOBILE_SEARCH"],
      // ["dailyMetrics", "BUSINESS_FOOD_MENU_CLICKS"],
      // ["dailyMetrics", "BUSINESS_FOOD_ORDERS"],
      // ["dailyMetrics", "BUSINESS_CONVERSATIONS"],
      // ["dailyMetrics", "BUSINESS_BOOKINGS"],
      ["dailyRange.start_date.year", startDate.slice(0, 4)],
      ["dailyRange.start_date.month", startDate.slice(5, 7)],
      ["dailyRange.start_date.day", startDate.slice(8, 10)],
      ["dailyRange.end_date.year", endDate.slice(0, 4)],
      ["dailyRange.end_date.month", endDate.slice(5, 7)],
      ["dailyRange.end_date.day", endDate.slice(8, 10)],
    ])
    const url = 'https://businessprofileperformance.googleapis.com/v1/' + locationName + ':fetchMultiDailyMetricsTimeSeries?' + queryString;
    console.log("QUERY STRING FOR CALL IS", url)
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.accessToken
      },
    });
    const json = await response.json()
    if (json["error"] === undefined) {
      console.log("******** retrived location insight*********")
      console.log(json);
      console.log("*********************************************");
      return json
    } else {
      console.log("******** retrived error during insight*********")
      console.log(json);
      console.log("*********************************************");
    }
  }

  static async postEvent(user: User, account: Account, locationNames: string[], data: EventFormData): Promise<any> {
    const eventBody = {
      languageCode: "en-US",
      summary: data.title,
      topicType: "EVENT",
      event: {
        title: data.title,
        schedule: {
          startDate: {
            year: data.startDate.getFullYear(),
            month: data.startDate.getMonth() + 1,
            day: data.startDate.getDate(),
          },
          startTime: {
            hours: data.startDate.getHours(),
            minutes: data.startDate.getMinutes(),
            seconds: data.startDate.getSeconds,
            nanos: 0,
          },
          endDate: {
            year: data.endDate.getFullYear(),
            month: data.endDate.getMonth() + 1,
            day: data.endDate.getDate(),
          },
          endTime: {
            hours: data.endDate.getHours(),
            minutes: data.endDate.getMinutes(),
            seconds: data.endDate.getSeconds,
            nanos: 0,
          },
        },
      }
    } as any

    if (data.details) {
      eventBody.summary = data.details
    }

    if (data.buttonType) {
      eventBody.callToAction = {
        actionType: data.buttonType,
        url: data.buttonLink
      }
    }

    if (data.uploadedFiles.length > 0) {
      eventBody.media = data.uploadedFiles.map(fileInfo => {
        return {
          mediaFormat: "PHOTO", 
          sourceUrl: fileInfo.url!
        }
      })
    }
    console.log("EventBody", eventBody)
    console.log("locationNames", locationNames);

    let locParams = locationNames.map(locationName => account.name + '/' + locationName);
    let asyncCall = []
    for (const loc of locParams) {
      const url = 'https://mybusiness.googleapis.com/v4/' + loc + '/localPosts';
      console.log("posting event to ", url)
      asyncCall.push(fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + user.accessToken
        },
        body: JSON.stringify(eventBody),
      }))
    }
    const response = await Promise.all(asyncCall)
    console.log("******** Posted event*********")
    console.log(response);
    console.log("*********************************************");
    return response;
  }

  static async updatePin(user: User, account: Account, locationName: string, newLocation: PinLocation): Promise<any> {
    const url = 'https://mybusiness.googleapis.com/v4/'
      + account.name + '/' + locationName 
      + '?updateMask=latlng.latitude,latlng.longitude';

    console.log("posting event to ", newLocation, url)
    const body = 
    { 
      "latlng": {
        "latitude": newLocation.lattitude,
        "longitude": newLocation.longitude
      }
    };

    const response = await fetch(url, {
      method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.accessToken
      },
      body: JSON.stringify(body),
    });
    const json = await response.json()
    console.log("******** Updaterd Pin*********")
    console.log(json);
    console.log("*********************************************");
    return json;
  }

  static async validateToken(token: string) {
    const url = `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${token}`;
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const { error } = await response.json();
    return !error;
  }
}
