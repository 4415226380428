import { Grid, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';

const time_ranges = [
    {value: '2400',label: '24 hours'},
    {value: '0000',label: '12:00 AM'},
    {value: '0030',label: '12:30 AM'},
    {value: '0100',label: '1:00 AM'},
    {value: '0130',label: '1:30 AM'},
    {value: '0200',label: '2:00 AM'},
    {value: '0230',label: '2:30 AM'},
    {value: '0300',label: '3:00 AM'},
    {value: '0330',label: '3:30 AM'},
    {value: '0400',label: '4:00 AM'},
    {value: '0430',label: '4:30 AM'},
    {value: '0500',label: '5:00 AM'},
    {value: '0530',label: '5:30 AM'},
    {value: '0600',label: '6:00 AM'},
    {value: '0630',label: '6:30 AM'},
    {value: '0700',label: '7:00 AM'},
    {value: '0730',label: '7:30 AM'},
    {value: '0800',label: '8:00 AM'},
    {value: '0830',label: '8:30 AM'},
    {value: '0900',label: '9:00 AM'},
    {value: '0930',label: '9:30 AM'},
    {value: '1000',label: '10:00 AM'},
    {value: '1030',label: '10:30 AM'},
    {value: '1100',label: '11:00 AM'},
    {value: '1130',label: '11:30 AM'},
    {value: '1200',label: '12:00 PM'},
    {value: '1230',label: '12:30 PM'},
    {value: '1300',label: '1:00 PM'},
    {value: '1330',label: '1:30 PM'},
    {value: '1400',label: '2:00 PM'},
    {value: '1430',label: '2:30 PM'},
    {value: '1500',label: '3:00 PM'},
    {value: '1530',label: '3:30 PM'},
    {value: '1600',label: '4:00 PM'},
    {value: '1630',label: '4:30 PM'},
    {value: '1700',label: '5:00 PM'},
    {value: '1730',label: '5:30 PM'},
    {value: '1800',label: '6:00 PM'},
    {value: '1830',label: '6:30 PM'},
    {value: '1900',label: '7:00 PM'},
    {value: '1930',label: '7:30 PM'},
    {value: '2000',label: '8:00 PM'},
    {value: '2030',label: '8:30 PM'},
    {value: '2100',label: '9:00 PM'},
    {value: '2130',label: '9:30 PM'},
    {value: '2200',label: '10:00 PM'},
    {value: '2230',label: '10:30 PM'},
    {value: '2300',label: '11:00 PM'},
    {value: '2330',label: '11:30 PM'}
]

export function FromToTimeRange(props: any) {

    const [data, setData] = useState({from: '',to:''});

    const handleFromChange = (event: any) => {
        let temp = JSON.parse(JSON.stringify(data))
        temp.from = event.target.value;
        setData(temp)
        props.onChange(temp)
    }

    const handleToChange = (event: any) => {
        let temp = JSON.parse(JSON.stringify(data))
        temp.to = event.target.value;
        setData(temp)
        props.onChange(temp)
    }

    return (
        <div>
            <FormControl><InputLabel id='opens-at'>{props.fromLabel}</InputLabel>
                <Select id='opens-at' style={{ minWidth: '130px', marginRight: '10px' }}
                    labelId='opens-at'
                    label={props.fromLabel}
                    onChange={handleFromChange}
                >
                    {
                        time_ranges.map(item => <MenuItem value={item.value}>{item.label}</MenuItem>)
                    }
                </Select>
            </FormControl>
            {data.from != '2400' && 
            <FormControl >
                <InputLabel id='closes-at'>{props.toLabel}</InputLabel>
                <Select style={{ minWidth: '130px' }}
                    labelId='closes-at'
                    label={props.toLabel}
                    onChange={handleToChange}
                >
                    {
                        time_ranges.map(item => <MenuItem value={item.value}>{item.label}</MenuItem>)
                    }
                </Select>
            </FormControl>}
                
        </div>
    );
}