const BaseURL = process.env.REACT_APP_PLEPER_API_URL
const PleperApiKey = process.env.REACT_APP_PLEPER_API_KEY

export class PleperApiService {
  static async mapSearch(fileName: string, keyword: string): Promise<any> {
    return new Promise(function(resolve, reject) {
      let batchSize = 10
      try {
        let jsonData = require('./json/' + fileName);
        let allKeys = Object.keys(jsonData);
        let batchIds: any[] = []
        let allPromise2: any[] = [];

        console.log('allKeys.length=', allKeys.length)
        function next() {
          if (allKeys.length > 0) {
            console.log('allKeys.length=', allKeys.length)
            let spl = allKeys.length > batchSize ? (allKeys.length - batchSize) : 0;

            let keys = allKeys.splice(spl)
            console.log('mapSearch::Getting data with keys =', keys)
            let promisAll = []
            for (let k of keys) {
              let obj = jsonData[k];
              var data = new FormData();
              data.append('api-key', `${PleperApiKey}`)
              data.append('batch_id', 'new_commit')
              data.append('keyword', keyword)
              data.append('latitude', obj.latitude)
              data.append('longitude', obj.longitude)
              const url =`${BaseURL}/v3/google/search/maps`
              promisAll.push(fetch(url, {
                method: 'POST',
                body: data
              }))
            }
            Promise.all(promisAll).then(function(values) {
              values.forEach(json => {
                allPromise2.push(json.json())
              })
              next();
            });
          } else {
            console.log('Running next2()...', allPromise2)
            next2()
          }
        }

        // start first iteration
        delay(2000).then(()=>{
          next();
        })

        function next2() {
            if (allPromise2.length > 0) {
              let spl = allPromise2.length > batchSize ? allPromise2.length - batchSize : 0;
              console.log('getBatchResults::next2::allPromise2.length =', allPromise2.length)
              Promise.all(allPromise2.splice(spl)).then(function(values2) {
                  values2.forEach(json => {
                    batchIds.push(json)
                  })
                  next2();
              });
            } else {
              resolve(batchIds)
            }
        }
      } catch (err) {
        console.error('Error when call Pleper map search', err)
      }
    })
  }

  static async getBatchResults(batchs: any[], apiName: string) {
    return new Promise(function(resolve, reject) {
      try {
        let batchSize = 10;
        function next() {
          if (batchs.length > 0) {
            console.log('batchs.length=', batchs.length)
            let spl = (batchs.length > batchSize) ? (batchs.length - batchSize) : 0;

            let keys = batchs.splice(spl)
            console.log('getBatchResults::Getting data with keys =', keys)
            let promisAll = []
            for (let k of keys) {
              try {
                let url =`${BaseURL}/v3/batch_get_results?api_key=${PleperApiKey}&batch_id=${k.batch_id}`
                promisAll.push(fetch(url, {
                  method: 'GET'
                }))
              } catch (err) {
                console.error('Error at key ', k)
                reject(err)
              }
            }
            Promise.all(promisAll).then(function(values) {
              values.forEach(json => {
                allPromise2.push(json.json())
              })
              next();
            });
          } else {
            console.log('Running next2()...', allPromise2)
            next2()
          }
        }

        // start
        next();

        let allPromise2: any[] = [];
        let results: any[] = []
        function next2() {
          if (allPromise2.length > 0) {
            let spl = allPromise2.length >= batchSize ? allPromise2.length - batchSize : 0;
            console.log('getBatchResults::next2::Getting data with keys =', allPromise2.length)
            Promise.all(allPromise2.splice(spl)).then(function(values2) {
                values2.forEach(res => {
                  if (res.status == 'Finished') {
                    let allData = Object.values(res.results[apiName][0].results)
                    results = results.concat(allData)
                  }
                })
                next2();
            });
          } else {
            resolve(results)
          }
        }
        
      } catch (err) {
        console.error('Error when call Pleper map search', err)
      }
    });
  }

  static async getListingBatchResults(batchs: any[], apiName: string) {
    return new Promise(function(resolve, reject) {
      try {
        let batchSize = 10;
        function next() {
          if (batchs.length > 0) {
            console.log('batchs.length=', batchs.length)
            let spl = (batchs.length > batchSize) ? (batchs.length - batchSize) : 0;

            let keys = batchs.splice(spl)
            console.log('getBatchResults::Getting data with keys =', keys)
            let promisAll = []
            for (let k of keys) {
              try {
                let url =`${BaseURL}/v3/batch_get_results?api_key=${PleperApiKey}&batch_id=${k.batch_id}`
                promisAll.push(fetch(url, {
                  method: 'GET'
                }))
              } catch (err) {
                console.error('Error at key ', k)
                reject(err)
              }
            }
            Promise.all(promisAll).then(function(values) {
              values.forEach(json => {
                allPromise2.push(json.json())
              })
              next();
            });
          } else {
            console.log('Running next2()...', allPromise2)
            next2()
          }
        }

        // start
        next();

        let allPromise2: any[] = [];
        let results: any
        function next2() {
          if (allPromise2.length > 0) {
            let spl = allPromise2.length >= batchSize ? allPromise2.length - batchSize : 0;
            console.log('getBatchResults::next2::Getting data with keys =', allPromise2.length)
            Promise.all(allPromise2.splice(spl)).then(function(values2) {
                values2.forEach(res => {
                  if (res.status == 'Finished') {
                    results = res.results[apiName][0].results
                  }
                })
                next2();
            });
          } else {
            resolve(results)
          }
        }
        
      } catch (err) {
        console.error('Error when call Pleper map search', err)
      }
    });
  }

  static async getListingInformation(profile_url: string): Promise<any> {
    var data = new FormData();
    data.append('api-key', `${PleperApiKey}`)
    data.append('batch_id', 'new_commit')
    data.append('profile_url', profile_url)
    let url =`${BaseURL}/v3/google/by-profile/information`
    const response = await fetch(url, {
      method: 'POST',
      body: data
    })
    const json = await response.json();  
    console.log("******** retrived batch information *********")
    console.log(json);
    console.log("*********************************************");

    return json;
  }

}

function delay(time: any) {
  return new Promise(resolve => setTimeout(resolve, time));
}
