import * as React from 'react';
import '../components/style.css'
import Grid from '@mui/material/Grid';
import { Alert, AlertColor, Backdrop, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MediaUploader, { UploadableFile } from './MediaUploader';
import { RepositoryImpl } from 'data/RepositoryImpl';
import { EventFormData } from 'domain/Repository';

export default function EventForm(props: any) {
  const today = new Date();
  const tmr = new Date();
  tmr.setDate(today.getDate() + 1);
  const [title, setTitle] = React.useState<string | null>(null)
  const [startDate, setStartDate] = React.useState<Date | null>(today);
  const [endDate, setEndDate] = React.useState<Date | null>(tmr);
  const [details, setDetails] = React.useState<string | null>(null)
  const [buttonType, setButtonType] = React.useState<string | null>(null)
  const [buttonLink, setButtonLink] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [message, setMessage] = React.useState({
    open: false,
    type: 'success' as AlertColor,
    content: 'LocalPost has been created successfully.'
  });
  
  const postEvent = RepositoryImpl((state) => state.postEvent);

  const [uploadedFiles, setUploadedFiles] = React.useState<UploadableFile[]>([]);
  const [uploadedFile, setUploadedFile] = React.useState<any>();

  const handleUploadedMedia = (file: any) => {
    console.log("received info about upload", file)
    setUploadedFile(file)
  }

  const resetForm = () => {
    setTitle('')
    setStartDate(today)
    setEndDate(tmr)
    setDetails('')
    setButtonType('')
    setButtonLink('')
    setUploadedFile(null)
  }

  const onEventSubmit =  async () => {
    console.log('submitted');
    if (title === '') {
      alert("Please provide a valid title")
      return;
    }
    if (startDate === endDate) {
      alert("Event start and end date can not be exactly the same")
      return;
    }
    if (startDate && endDate && endDate <= startDate) {
      setMessage({
        open: true,
        type: 'error',
        content: 'Start Date must before End Date. Please check again.'
      })
      return;
    }

    const data: EventFormData = {
      title: title!,
      startDate: startDate!,
      endDate: endDate!,
      details: details,
      buttonType: buttonType,
      buttonLink: buttonLink,
      uploadedFiles: (uploadedFile && uploadedFile?.data) ? [{
          "url": uploadedFile ? uploadedFile.data?.link : ""
      }] : []
    }

    setLoading(true)
    const { name, searchUrl, error} = await postEvent(data);
    if (error && error.details && error.details[0] && error.details[0].errorDetails[0]) {
      setMessage({
        open: true,
        type: 'error' as AlertColor,
        content: error.details[0].errorDetails[0].message
      });
    }
    if (!error) {
      let message = searchUrl ? `LocalPost has been created successfully. Link: ${searchUrl}` : `LocalPost has been created successfully.`
      setMessage({
        open: true,
        type: 'success' as AlertColor,
        content: message
      });
      resetForm();
    }
    setLoading(false)
  }

  return (
      <Grid container spacing={3} paddingLeft="20px">
      <Grid item xs={4}>

      <FormControl fullWidth>
        <br/>
        <Backdrop
            sx={{
              zIndex: 20
            }}
            open={loading}
          >
            <CircularProgress color='inherit' />
        </Backdrop>
        <Snackbar
          open={message.open}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={message.type === 'success' ? 7000 : 5000}
          onClose={() => setMessage({ ...message, open: false })}
        >
          <Alert severity={message.type}>{message.content}</Alert>
        </Snackbar>
        <TextField 
          id="title" 
          label="Title" 
          variant="outlined"
          value={title}
          onChange={(event: any) => {setTitle(event.target.value)} } />
        <br/>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Start Date Time"
            value={startDate}
            onChange={setStartDate}
            renderInput={(params) => <TextField {...params} />}
          />
          <br/>
          <DateTimePicker
            label="End Date Time"
            value={endDate}
            onChange={setEndDate}
            minDateTime={startDate || new Date()}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <br /><br/>
        <Typography variant="h2" component="div" align="center">Add More Details (Optional)</Typography>
        <br/>
        <TextField
          id="eventDetails"
          label="Event Details (Optional)"
          multiline
          rows={2}
          maxRows={3}
          value={details}
          onChange={ (event: any) => {setDetails(event.target.value)} }
        />
        <br />
          <FormControl>
          <InputLabel id="buttonSelectLabel">Add Button</InputLabel>
          <Select
            labelId="buttonSelectLabel"
            id="buttonSelect"
            label="Button Type"
            value={buttonType}
            onChange={ (event: any) => {setButtonType(event.target.value)} }
          >
            <MenuItem value={'BOOK'}>Book</MenuItem>
            <MenuItem value={'ORDER'}>Order Online</MenuItem>
            <MenuItem value={'SHOP'}>Buy</MenuItem>
            <MenuItem value={'LEARN_MORE'}>Learn More</MenuItem>
            <MenuItem value={'SIGN_UP'}>Sign Up</MenuItem>
            <MenuItem value={'CALL'}>Call Now</MenuItem>

          </Select>
          </FormControl>
          <br />
          <TextField 
            id="buttonLink" 
            label="Link for your button"
            variant="outlined" 
            value={buttonLink}
            onChange={ (event: any) => {setButtonLink(event.target.value)} }
          />
          <br />
          <MediaUploader filesLimit={1} setUploadedFiles={handleUploadedMedia} />
          <br />
          <Button
            variant="contained"
            component="label"
            disabled={!title}
            onClick={onEventSubmit}
          >
            Submit
          </Button>
      </FormControl>
      </Grid>
      <Grid item xs={8} />
      </Grid>
  )
}
