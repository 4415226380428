import * as React from 'react';
import Switch from '@mui/material/Switch';
import { FormControlLabel } from '@mui/material';

export default function OpenCloseSwitch() {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Switch
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        />
      }
      labelPlacement="end"
      label={checked ? "Open" : "Closed"}
    />
  );
}