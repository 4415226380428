import Box from '@mui/material/Box';
import {Typography} from "@mui/material";
import {InfoOutlined }from "@mui/icons-material";
import Grid from '@mui/material/Grid';

import stat_calls from '../images/stat_calls.svg'
import stat_visits from '../images/stat_visits.svg'
import stat_direction from '../images/stat_direction.svg'

import "components/style.css";


interface StatCardProps {
  title: string,
  subtitle: string,
  month_diff: string,
  month_diff_sign: number,
  year_diff: string,
  year_diff_sign: number
}

function StatCard(props: StatCardProps, image: any) {
  return(
    <Box
      component='div'
      sx={{
        width: '100%',
        backgroundColor: 'var(--yin_bg)',
        borderRadius: 2,
        boxShadow: 2
      }}
    >
    <Grid container padding='20px 10px' justifyContent="center">
        <Grid item xs={6}>
          <img alt="Mapforce" src={image} width="50"/>
        </Grid>
        <Grid item xs={6} textAlign='right'>
        <InfoOutlined className="stat-icon"/>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">{props.subtitle}</Typography>
        </Grid>
        <Grid item xs={2.5}/>
        <Grid item xs={3.5} textAlign='right'>
          {
            props.month_diff_sign > 0 ? 
            <Typography borderRadius='20px' bgcolor="var(--yin_green_bg)" variant='body1'>
              &#8593;&nbsp; {props.month_diff}
            </Typography>
            : 
            <Typography borderRadius='20px' bgcolor="var(--yin_orange_bg)" variant='body1'>
              &#8595;&nbsp; {props.month_diff}
            </Typography>
          }
          <Typography variant='body2'>
            vs. last month
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h3">{parseInt(props.title).toLocaleString()}</Typography>
        </Grid>
        <Grid item xs={2.5}/>
        <Grid item xs={3.5} textAlign='right'>
          {
            props.year_diff_sign > 0 ? 
            <Typography borderRadius='20px' bgcolor="var(--yin_green_bg)" variant='body1'>
              &#8593;&nbsp; {props.year_diff}
            </Typography>
            : 
            <Typography borderRadius='20px' bgcolor="var(--yin_orange_bg)" variant='body1'>
              &#8595;&nbsp; {props.year_diff}
            </Typography>
          }
          <Typography variant='body2'>
            vs. last year
          </Typography>
        </Grid>
    </Grid>
    </Box>
  )
}

function StatCardDirection(props: StatCardProps) {
  return StatCard(props, stat_direction);
}

function StatCardCalls(props: StatCardProps) {
  return StatCard(props, stat_calls);
}

function StatCardVisits(props: StatCardProps) {
  return StatCard(props, stat_visits);
}

export  {StatCardDirection, StatCardCalls, StatCardVisits};
