import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Alert, Link, List, ListItem, Snackbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RepositoryImpl } from "data/RepositoryImpl";

import { ApiService } from 'data/ApiService';

import "components/style.css";
import logo from 'images/logo_normal.svg'; 
import banner from 'images/banner-mapforce.png';

type FormValues = {
  firstName: string
  lastName: string
  email: string
  password: string
};

function RegisterForm() {
  const navigate = useNavigate();
  const loggedIn = RepositoryImpl((state) => state.loggedIn);
  const {register, handleSubmit, formState: {isValid}} = useForm<FormValues>()

  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false);

  const onRegister: SubmitHandler<FormValues> = async (data) => {
    const res = await ApiService.register(data)
    if (res.error && res.error.message) {
      setError(res.error.message)
    } else {
      const { user: { email, firstName, lastName }, token } = res;
      loggedIn(`${firstName} ${lastName}`, email, token, 'member');
      setSuccess(true);
    }
  };

  return (
    <div className="login-container">
      {/* error toast message */}
      <Snackbar
        open={!!error}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setError('')}
      >
        <Alert severity='error'>{error}</Alert>
      </Snackbar>

      {/* success toast message */}
      <Snackbar
        open={success}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => {
          setSuccess(false);
          navigate('/performance');
        }}
      >
        <Alert severity='success'>Your account has been created successfully!</Alert>
      </Snackbar>
      <Box
        sx={{
          flex: 1,
          display: 'flex'
        }}
        component='div'
      >
        <Box
          component='div'
          sx={{
            margin: 'auto',
            width: '100%',
            maxWidth: '360px'
          }}
        >
          <img alt="Mapforce" src={logo} width="200"/>
          <Typography component="div" sx={{ marginTop: '40px', fontSize: 36, fontWeight: 600 }}>
            Register to MapForce
          </Typography>
          <form onSubmit={handleSubmit(onRegister)}>
            <List>
              <ListItem sx={{ px: 0, py: 0 }}>
                <TextField
                  id="outlined-basic"
                  type="text"
                  label="First Name*"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  {...register('firstName', { required: true })}
                />
              </ListItem>
              <ListItem sx={{ px: 0, py: 0 }}>
                <TextField
                  id="outlined-basic"
                  type="text"
                  label="Last Name*"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  {...register('lastName', { required: true })}
                />
              </ListItem>
              <ListItem sx={{ px: 0, py: 0 }}>
                <TextField
                  id="outlined-basic"
                  type="email"
                  label="Email*"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  {...register('email', { required: true })}
                />
              </ListItem>
              <ListItem sx={{ px: 0,  py: 0 }}>
                <TextField
                  id="outlined-basic"
                  label="Password*"
                  type="password"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  {...register('password', { required: true })}
                />
              </ListItem>
              <ListItem sx={{ px: 0 }}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ textTransform: 'unset' }}
                  disabled={!isValid}
                >
                  Sign up
                </Button>
              </ListItem>
              <ListItem sx={{ px: 0 }}>
                <Box
                  component='div'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    marginTop: '20px',
                    width: '100%'
                  }}
                >
                  <Typography variant='body2' sx={{ color: '#667085' }}>
                    Already have an account?
                  </Typography>
                  <Link
                    sx={{ fontSize: 14, textDecoration: 'unset', cursor: 'pointer' }}
                    onClick={() => navigate('/login')}
                  >
                    Sign in
                  </Link>
                </Box>
              </ListItem>
            </List>
          </form>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: { xs: 'none', lg: 'block' },
          backgroundImage: `url(${banner})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
        component='div'
      />
    </div>
  );
};

export default RegisterForm;