import * as React from 'react';
import '../components/style.css'
import Sidebar from 'components/Sidebar'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Backdrop, Button, CircularProgress, FormControl, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';
import {StatCardDirection, StatCardCalls, StatCardVisits} from 'components/StatCard';
import chart from '../images/revenue_chart.png';
import Header from 'components/Header'
import { RepositoryImpl } from 'data/RepositoryImpl';
import BasicDateRangePicker from 'components/BasicDateRangePicker';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { LocationInsight } from 'domain/Repository';
import DonutChart from 'components/DonutChart';

interface PerformanceContentProps {
  range: DateRange<Date>
  setRange: (newRange: DateRange<Date>) => void
  insights: LocationInsight
  monthlyDiff: LocationInsight
  yearlyDiff: LocationInsight
  averageInputDisabled: boolean
}

function PerformanceContent(props: PerformanceContentProps) {
  let mDiffDR = props.monthlyDiff.directionRequests ? props.monthlyDiff.directionRequests.toFixed(0) + "%" : "NA"
  if (mDiffDR === "Infinity%") {
    mDiffDR = "NA"
  }
  let mDiffPC = props.monthlyDiff.phoneCalls ? props.monthlyDiff.phoneCalls.toFixed(0) + "%" : "NA"
  if (mDiffPC === "Infinity%") {
    mDiffPC = "NA"
  }
  let mDiffWV = props.monthlyDiff.websiteVisits ? props.monthlyDiff.websiteVisits.toFixed(0) + "%" : "NA"
  if (mDiffWV === "Infinity%") {
    mDiffWV = "NA"
  }

  let yDiffDR = props.yearlyDiff.directionRequests ? props.yearlyDiff.directionRequests.toFixed(0) + "%" : "NA"
  if (yDiffDR === "Infinity%") {
    yDiffDR = "NA"
  }
  let yDiffPC = props.yearlyDiff.phoneCalls ? props.yearlyDiff.phoneCalls.toFixed(0) + "%" : "NA"
  if (yDiffPC === "Infinity%") {
    yDiffPC = "NA"
  }
  let yDiffWV = props.yearlyDiff.websiteVisits ? props.yearlyDiff.websiteVisits.toFixed(0) + "%" : "NA"
  if (yDiffWV === "Infinity%") {
    yDiffWV = "NA"
  }
  const [avgValue, setAvgValue] = React.useState(0)
  const averageTransactionValue = RepositoryImpl(state => state.averageTransactionValue)
  const selectedLocations = RepositoryImpl(state => state.selectedLocations)
  const updateAverageTransactionValue = RepositoryImpl(state => state.updateAverageTransactionValue)

  React.useEffect(() => {
    setAvgValue(averageTransactionValue)
  }, [averageTransactionValue])
  
  return (
    <Box component='div'>
      <Grid container spacing={3} marginBottom='10px'>
        <Grid item lg={8}>
          <Typography variant="h1" component="div" align="left" style={{float: 'left'}}>Location Performance&nbsp;&nbsp;</Typography><div style={{float: 'left', fontSize: '40px'}}>{selectedLocations.length}&nbsp;Locations</div>
        </Grid>
        <Grid item lg={4}>
        <BasicDateRangePicker range = { props.range } setRange = { props.setRange } />
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 1, lg: 3 }}>
        <Grid item xs={12} lg={4}>
          <StatCardDirection 
            title={props.insights.directionRequests.toString()} 
            subtitle="Direction Requests" 
            month_diff= { mDiffDR } 
            year_diff={ yDiffDR} 
            month_diff_sign={props.monthlyDiff.directionRequests} 
            year_diff_sign={props.yearlyDiff.directionRequests}/>
        </Grid>
        <Grid item xs={12} lg={4}>
          <StatCardCalls 
            title={props.insights.phoneCalls.toString()} 
            subtitle="Phone Calls" 
            month_diff={ mDiffPC } 
            year_diff={ yDiffPC }
            month_diff_sign={props.monthlyDiff.phoneCalls} 
            year_diff_sign={props.yearlyDiff.phoneCalls}/>
        </Grid>
        <Grid item xs={12} lg={4}>
          <StatCardVisits 
            title={props.insights.websiteVisits.toString()} 
            subtitle="Website Visits" 
            month_diff={ mDiffWV } 
            year_diff={ yDiffWV }
            month_diff_sign={props.monthlyDiff.websiteVisits} 
            year_diff_sign={props.yearlyDiff.websiteVisits}/>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box
            component='div'
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              backgroundColor: 'var(--yin_bg)',
              borderRadius: 2,
              boxShadow: 2
            }}
          >
            <Box component='div' sx={{ py: 2, width: '100%' }}>
              <Typography variant="body1" component="div" align="center">Map Search Breakdown</Typography>
              <div style={{
                marginTop: 8,
                marginLeft: '15%',
                width: '70%',
                }}>
                <DonutChart
                  directQueriesDesktop={props.insights.directQueriesDesktop} 
                  directQueriesMobile={props.insights.directQueriesMobile} 
                  indirectQueriesDesktop={props.insights.indirectQueriesDesktop}
                  indirectQueriesMobile={props.insights.indirectQueriesMobile}
                />
              </div>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box
            component='div'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              height: '100%',
              backgroundColor: 'var(--yin_bg)',
              borderRadius: 2,
              boxShadow: 2,
              inset: 2
            }}
          >
            <Box
              component='div'
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                pt: 3,
                px: 3
              }}
            >
              <Box component='div'>
                <Typography variant='h6' sx={{ color: '#667085' }}>Revenue Correlation</Typography>
                <Typography variant='h3' sx={{ letterSpacing: 1.5 }}>
                  {`$${(props.insights.directionRequests * averageTransactionValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                </Typography>
              </Box>
              <FormControl
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '4px',
                  width: 280,
                }}
              >
                <InputLabel htmlFor='average-transaction-value-input'>
                  Set Average Transaction Value ($)
                </InputLabel>
                <OutlinedInput
                  id='average-transaction-value-input'
                  type='number'
                  label='Set Average Transaction Value ($)'
                  startAdornment={
                    <InputAdornment position='start'>$</InputAdornment>
                  }
                  disabled={props.averageInputDisabled}
                  value={avgValue}
                  onChange={(e) => setAvgValue(Number(e.target.value))}
                />
                <Button
                  sx={{
                    padding: '4px 8px',
                    minWidth: 'unset'
                  }}
                  onClick={() => updateAverageTransactionValue(avgValue)}
                >
                  <PublishIcon />
                </Button>
              </FormControl>
            </Box>
            <Box
              sx={{
                flex: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mx: 3,
                mb: 3,
                backgroundImage: `url(${chart})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }}
              component='div'
            >
              <Typography variant='h4' component='div' sx={{ p: 2, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                Coming soon
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

function Performance(props: any) {
  const domainDateRange = RepositoryImpl((state) => state.selectedDateRange);
  const domainSetDateRange = RepositoryImpl((state) => state.setSelectedDateRange);
  
  const selectedLocations = RepositoryImpl((state) => state.selectedLocations);
  
  const insights = RepositoryImpl((state) => state.insightCumulative);
  const monthlyDiff = RepositoryImpl((state) => state.monthlyChange);
  const yearlyDiff = RepositoryImpl((state) => state.yearlyChange);
  const fetchInsights = RepositoryImpl((state) => state.fetchInsights)
  const loading = RepositoryImpl((state) => state.loading)

  const setRangeWrapper = (newRange: DateRange<Date>) => {
    domainSetDateRange([newRange[0]!.toISOString(), newRange[1]!.toISOString()])
  }

  React.useEffect(() => {
    fetchInsights();
  }, [selectedLocations, domainDateRange, fetchInsights]);

  return (
    <div className="container">
      <Backdrop
        sx={{
          zIndex: 20
        }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Sidebar />
      <div className="content-area">
        <Header />  
        <div className="hero">
          <PerformanceContent
            insights = { insights } 
            monthlyDiff = { monthlyDiff }
            yearlyDiff = { yearlyDiff }
            range = { [new Date(domainDateRange[0]), new Date(domainDateRange[1])] }
            setRange = { setRangeWrapper }
            averageInputDisabled={!selectedLocations.length}
          />
        </div>
      </div>
    </div>
  )
}

export default Performance;
