
import { Button, Dialog, DialogTitle, FormControl, Paper, Stack, styled } from '@mui/material';
import React from 'react';
import { DropzoneDialog } from 'react-mui-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { ImgService } from 'data/ImgService';


export interface UploadableFile {
  id?: string;
  file?: File;
  errors?: any;
  url?: string;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  paddingLeft: 10,
  paddingRight: 10,
}));

export interface MediaUploaderProps {
  setUploadedFiles: (uploadedFiles: UploadableFile[]) => any
  filesLimit: number
}

export default function MediaUploader(props: MediaUploaderProps) {
  const [dropzoneState, setDropzoneState] = React.useState(false);
  const [uploaderModalState, setUploaderModalState] = React.useState(false);
  const [dropzoneFiles, setDropzoneFiles] = React.useState<any>()
  const [files, setFiles] = React.useState<UploadableFile[]>([]);

  function onUpload(file: File, url: string) {
    setFiles((curr) =>
      curr.map((fw) => {
        if (fw.file === file) {
          return { ...fw, url };
        }
        return fw;
      })
    );
    if (props.filesLimit === 1) {
      onDoneUploader(null, null)
    }
  }

  function onDelete(file: File) {
    setFiles((curr) => curr.filter((fw) => fw.file !== file));
  }

  const onDoneDropzone = async (droppedFiles: any) => {
    console.log('dropped files:', droppedFiles);
    setDropzoneFiles(droppedFiles);
    const obj = droppedFiles.map((file: any) => ({ file, errors: [], id: uuidv4() }));
    setFiles(obj)
    setDropzoneState(false);
    setUploaderModalState(true)
    let res = await ImgService.upload(droppedFiles[0], 'image')
    props.setUploadedFiles(res)
  }

  const onDoneUploader = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setUploaderModalState(false)
    console.log('Uploaded files:', files);
    props.setUploadedFiles(files)
  }

  return (
    <FormControl>
    <Button variant="contained" color="primary" onClick={() => setDropzoneState(true)}>
      ADD IMAGE {files.length ? '('+files.length.toString()+')' : "" }  
    </Button>
    <DropzoneDialog
      acceptedFiles={['image/*']}
      cancelButtonText={"Cancel"}
      submitButtonText={"Upload"}
      filesLimit={props.filesLimit}
      showAlerts={false}
      maxFileSize={5000000}
      open={dropzoneState}
      onClose={() => setDropzoneState(false)}
      onSave={onDoneDropzone}
      showPreviews={true}
      showFileNamesInPreview={false}
    />

    {/* <Dialog
      onClose={onDoneUploader} 
      open={uploaderModalState}
    >
      <DialogTitle>Uploading Files</DialogTitle>
      <Stack spacing={2}>
        {files.map((fileWrapper: any) => (
          <Item key={fileWrapper.id}>
            {fileWrapper.errors.length ? (
              <UploadError
                file={fileWrapper.file}
                errors={fileWrapper.errors}
                onDelete={onDelete}
              />
            ) : (
              <SingleFileUploadWithProgress
                onDelete={onDelete}
                onUpload={onUpload}
                file={fileWrapper.file}
              />
            )}
          </Item>
        ))}
    </Stack>
    </Dialog> */}

    </FormControl>
  );
}
