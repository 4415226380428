import * as React from 'react';
import '../components/style.css'
import Sidebar from 'components/Sidebar'
import { Alert, AlertColor, Backdrop, CircularProgress, Snackbar, Typography } from '@mui/material';
import { Button } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { DropzoneDialog } from 'react-mui-dropzone';
import Header from 'components/Header'
import { GoogleApiService } from 'data/GoogleApiService';
import { RepositoryImpl } from 'data/RepositoryImpl';
import { ImgService } from 'data/ImgService';
import { ImgbbService } from 'data/ImgbbService';

function Media(props: any) {
  const [message, setMessage] = React.useState({
    open: false,
    type: 'success' as AlertColor,
    content: ''
  });

  const onUploadFinished = () => {
    setMessage({
      open: true,
      type: 'success' as AlertColor,
      content: 'Upload media successful.'
    })
  }
  
  return (
  <div className="container">
    <Sidebar />
    <div className="content-area">
      <Header />
      <Snackbar
          open={message.open}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={() => setMessage({ ...message, open: false })}
        >
          <Alert severity={message.type}>{message.content}</Alert>
      </Snackbar>
      <div className="hero">
        <Typography paddingLeft='20px' paddingTop='10px' variant="h1" component="div" align="left">
          Photos and Videos
        </Typography>
        
        <Typography paddingLeft='20px' paddingTop='10px' variant="body1" component="div" align="left">
          Post Photos and Videos for your location
        </Typography><br/>
        
        &nbsp;&nbsp;&nbsp;&nbsp;
        <UploadPhotoCategory filesLimit={1} fileTypes={['image/jpg', 'image/jpeg', 'image/png']} buttonLabel='Add Image' mediaType='photo' maxFileSize={5000000} onFinished={onUploadFinished}/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <UploadPhotoCategory filesLimit={1} fileTypes={['video/*']} buttonLabel='Add Video' mediaType='video' maxFileSize={75000000} onFinished={onUploadFinished}/>
        <br/><br/>
      </div>
    </div>
  </div>
  )
}

interface UploadPhotoCategoryProps {
  filesLimit: number,
  buttonLabel: string,
  mediaType: string,
  fileTypes: string[],
  maxFileSize: number,
  onFinished: () => void
}
async function timeout(delay: number) {
  return new Promise( res => setTimeout(res, delay) );
}
function UploadPhotoCategory(props: UploadPhotoCategoryProps) {
  const [selectPhotoType, setSelectPhotoType] = React.useState('');
  const [openModal, setOpenModal] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false)
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [dropzoneState, setDropzoneState] = React.useState(false);
  const user = RepositoryImpl((state) => state.loggedInUser)
  const selectedAccount = RepositoryImpl((state) => state.selectedAccount)
  const selectedLocations = RepositoryImpl((state) => state.selectedLocations)
  let accountId: string
  let locationIds: string[] = []
  if (selectedAccount) {
    console.log('selectedAccount::', selectedAccount)
    accountId = selectedAccount?.name.split("accounts/")[1]
  }
  if (selectedLocations && selectedLocations.length > 0) {
    locationIds = selectedLocations.map(location => location?.split("locations/")[1])
    console.log('selectedLocations::', locationIds)
  }

  const handleButonClick = () => {
    if (props.mediaType.toLocaleLowerCase() === 'video') {
      setDropzoneState(true)
    } else {
      setOpenModal(true);
      setDropzoneState(false)
    }
  }

  const onSelectCategory = async (cat: string) => {
    setOpenModal(false)
    setSelectPhotoType(cat)
    setDropzoneState(true)
  }

  const onDoneDropzone = async (droppedFiles: any) => {
    console.log('dropped files:', droppedFiles, droppedFiles[0]?.size );
    if (!accountId || locationIds.length == 0) {
      alert('Please select group and location to upload media.')
      setDropzoneState(false)
      return
    }
    if (props.mediaType.toLocaleLowerCase() !== 'video' && droppedFiles && droppedFiles.length > 0 && (droppedFiles[0].size < 10500 || droppedFiles[0].size > 5000000)) {
      alert('Image size must be between 10KB and 5MB.')
      setDropzoneState(false)
      return
    }
    if (props.mediaType.toLocaleLowerCase() === 'video' && droppedFiles && droppedFiles.length > 0 && droppedFiles[0].size > 75000000) {
      alert('Video file size must be lower than 75MB.')
      setDropzoneState(false)
      return
    }
    if (props.mediaType.toLocaleLowerCase() === 'video' && droppedFiles && droppedFiles.length > 0 && droppedFiles[0].duration > 30) {
      alert('Video file duration must be lower than 30s.')
      setDropzoneState(false)
      return
    }
    if (user && accountId && droppedFiles && locationIds.length > 0 && droppedFiles.length > 0) {
      // await GoogleApiService.startUploadMedia(photoResourceName, user.accessToken, droppedFiles);
      // await GoogleApiService.linkMediaToBusiness(accountId, locationId, photoResourceName, 'PHOTO', selectPhotoType, user.accessToken);
      setDropzoneState(false)
      setLoading(true)
      let res: any
      let imgbbEnable = true // enable imgBB image hosting
      if (props.mediaType.toLocaleLowerCase() == 'video') {
        res = await ImgService.upload(droppedFiles[0], props.mediaType.toLocaleLowerCase() == 'video' ? 'video' : 'image')
      } else {
        // res = await ImgService.rapidUpload(droppedFiles[0], props.mediaType.toLocaleLowerCase() == 'video' ? 'video' : 'image')
        if (imgbbEnable) {
          res = await ImgbbService.upload(droppedFiles[0])
        } else {
          res = await ImgService.rapidUpload(droppedFiles[0], props.mediaType.toLocaleLowerCase() == 'video' ? 'video' : 'image')
        }
        
      }
      if (res && res.success) {
        timeout(2000).then(async () => {
          await GoogleApiService.linkMediaToBusiness(accountId, locationIds, imgbbEnable ? res?.data?.image?.url : res?.data?.link, props.mediaType.toLocaleUpperCase(), props.mediaType.toLocaleLowerCase() == 'video' ? 'ADDITIONAL' : selectPhotoType, user.accessToken);
          setLoading(false)
          props.onFinished();
        })
      } else {
        alert('There is an unexpected error when uploading media. Please try again.')
        console.log(res)
        setLoading(false)
      }
    }
  }

  const uploadPhotoModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 850,
    minHeight: 350,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const uploadPhotoCatStyle = {
    borderBottom: 'solid 1px #b1b1b1',
    marginTop: '10px',
    cursor: 'pointer'
  };

  return (
    <>
      <Button variant="contained" onClick={() => handleButonClick()}>{props.buttonLabel}</Button>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={uploadPhotoModalStyle}>
          {
            !dropzoneState && props.mediaType.toLowerCase() == 'photo' && 
            <>
              <Typography variant="h3" component="h2">
                Add photo
              </Typography>
              <Typography variant="h5" component="h2" sx={uploadPhotoCatStyle} onClick={() => onSelectCategory('ADDITIONAL')}>
                Photo
                <Typography variant="h6" component="h6">Let customers peek inside your business</Typography>
              </Typography>
              <Typography variant="h5" component="h2" sx={uploadPhotoCatStyle} onClick={() => onSelectCategory('LOGO')}>
                Logo
                <Typography variant="h6" component="h2">Let customers see your business identity when you posts photos or reply to reviews</Typography>
              </Typography>
              <Typography variant="h6" component="h2" sx={uploadPhotoCatStyle} onClick={() => onSelectCategory('COVER')}>
                Cover photo
                <Typography variant="h6" component="h2">Showcase your business to customers</Typography>
              </Typography>
            </>
          }
        </Box>
      </Modal>
      <Backdrop
            sx={{
              zIndex: 20
            }}
            open={loading}
          >
            <CircularProgress color='inherit' />
        </Backdrop>
      <DropzoneDialog
        acceptedFiles={props.fileTypes}
        cancelButtonText={"Cancel"}
        submitButtonText={"Upload"}
        filesLimit={props.filesLimit}
        showAlerts={false}
        maxFileSize={props.maxFileSize}
        open={dropzoneState}
        onClose={() => setDropzoneState(false)}
        onSave={onDoneDropzone}
        showPreviews={true}
        showFileNamesInPreview={false}
      />
    </>
  )
}

export default Media;
