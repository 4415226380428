const BaseURL = process.env.REACT_APP_IMGUR_API_URL
const RapidBaseURL = process.env.REACT_APP_RAPID_API_KEY

export class ImgService {

    static async upload(file: any, type: string) {
        const env = require('./imgur-token.json')
        const url = `${BaseURL}/3/upload`
        const formData = new FormData();
        formData.append(type, file)
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': `Bearer ${env.access_token}`
            },
        })
        const json = await response.json()
        return json
    }

    static async rapidUpload(file: any, type: string) {
        const url = `${RapidBaseURL}/3/image`
        const env = require('./imgur-token.json')
        const formData = new FormData();
        formData.append(type, file)
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'X-Mashape-Key': `${env.X_Mashape_Key}`,
                'Authorization': `Bearer ${env.access_token}`
            },
        })
        const json = await response.json()
        return json
    }
}