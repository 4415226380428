import * as React from 'react';
import '../components/style.css'
import Sidebar from 'components/Sidebar'
import Header from 'components/Header'

function Support(props: any) {
  return (
  <div className="container">
    <Sidebar />
    <div className="content-area">
      <Header />
      <div className="hero"></div>
    </div>
    </div>
  )
}

export default Support;
