import { User } from "domain/User";
import { dateLast30Day, dateToday, EventFormData, LocationInsight, Repository } from "domain/Repository";
import { GoogleApiService } from "data/GoogleApiService";
import create from "zustand"
import { persist } from "zustand/middleware"
import { PinLocation } from "components/MapWithMarker";
import { Account, AccountByAccountName, LocationNamesByAccountName } from "domain/Account";
import { ApiService } from "./ApiService";
import { PleperApiService } from "./PleperApiService";

const diffDays = (start: Date, end: Date) =>{
  let difference = end.getTime() - start.getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
}

const getLastMonth = (dateISO: string): string => {
  let d = new Date(dateISO)
  d.setDate(d.getDate() - 30)
  return d.toISOString()
}

const getLastYear = (dateISO: string): string => {
  let d = new Date(dateISO)
  d.setDate(d.getDate() - 365)
  return d.toISOString()
}

let dailyCache = (window as any).dailyCache

const calcInsights = 
  async (locationNames: string[], startDate: string, endDate: string, dailyCacheByLocation: {[key:string]:any} ) : Promise<[{[key:string]: LocationInsight}, LocationInsight] | null> => {
    if (dailyCache == null) {
      console.log(" Have no cached Data for calculating insights=========")
      return null
    }

    if (Object.keys(dailyCacheByLocation).length === 0) {
      return null;
    }

    const req_start_date = new Date(startDate)
    const req_end_date = new Date(endDate)
    var directionRequests = 0
    var phoneCalls = 0
    var websiteVisits = 0
    var directQueriesDesktop = 0
    var indirectQueriesDesktop = 0
    var directQueriesMobile = 0
    var indirectQueriesMobile = 0

    const newInsightByLocation = {} as {[key:string]: LocationInsight}
    for (let locationName of locationNames) {
      // console.log("Calc insights", locationName)
      const locInsight = { 
        directionRequests: 0,
        phoneCalls: 0, 
        websiteVisits: 0,
        directQueriesDesktop: 0,
        indirectQueriesDesktop: 0,
        directQueriesMobile: 0,
        indirectQueriesMobile: 0,
      } as LocationInsight
      // individual part
      const loc_id = locationName.slice(10) // remove prefix 'locations/' from the name
      let metrics = dailyCacheByLocation[loc_id]
      if (metrics == null) {
        console.log("No Metrics was found for the loation:", loc_id)
        continue
      }

      const cached_start_date = new Date(metrics.start_date)
      const cached_end_date = new Date(metrics.end_date)


      let loop_date = new Date(req_start_date)
      let countOfDays = diffDays(req_start_date, req_end_date)
      // console.log("req_start_date", req_start_date, "req_end_date", req_end_date, "CountDays", countOfDays)
      for (var j =0; j<= countOfDays; j++) {
        // console.log("Loop Date", loop_date)
        if (loop_date >= cached_start_date && loop_date <= cached_end_date) {
          let i = diffDays(cached_start_date, loop_date)
          // console.log("Looked Up Index", i)
          locInsight.directionRequests += metrics.BUSINESS_DIRECTION_REQUESTS[i]
          locInsight.phoneCalls += metrics.CALL_CLICKS[i]
          locInsight.websiteVisits += metrics.WEBSITE_CLICKS[i]
          locInsight.directQueriesDesktop += metrics.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH[i]
          locInsight.indirectQueriesDesktop += metrics.BUSINESS_IMPRESSIONS_DESKTOP_MAPS[i]
          locInsight.directQueriesMobile += metrics.BUSINESS_IMPRESSIONS_MOBILE_SEARCH[i]
          locInsight.indirectQueriesMobile += metrics.BUSINESS_IMPRESSIONS_MOBILE_MAPS[i]
        } else {
          // console.log("Skipping Loop Date", loop_date)
        }
        loop_date.setDate(loop_date.getDate() + 1)
      }

      newInsightByLocation[locationName] = locInsight
      
      // cumulative part across locations
      directionRequests += locInsight.directionRequests;
      websiteVisits += locInsight.websiteVisits;
      phoneCalls += locInsight.phoneCalls;
      directQueriesDesktop += locInsight.directQueriesDesktop;
      indirectQueriesDesktop += locInsight.indirectQueriesDesktop;
      directQueriesMobile += locInsight.directQueriesMobile;
      indirectQueriesMobile += locInsight.indirectQueriesMobile
    }

    const newCumulativeInsights = {
      directionRequests: directionRequests,
      websiteVisits: websiteVisits,
      phoneCalls: phoneCalls,
      directQueriesDesktop: directQueriesDesktop,
      indirectQueriesDesktop: indirectQueriesDesktop,
      directQueriesMobile: directQueriesMobile,
      indirectQueriesMobile: indirectQueriesMobile,
    } as LocationInsight;
    
    return [newInsightByLocation, newCumulativeInsights]
}

export const RepositoryImpl = create<Repository>(persist((set, get) => ({
  loading: false,
  loggedInUser: undefined as User | undefined,
  accounts: {} as AccountByAccountName,
  locationNamesByAccountName: {} as LocationNamesByAccountName,
  locations: {} as {[key:string]: any},
  insightByLocation: {} as {[key:string]: LocationInsight},
  insightByLocationLastMonth: {} as {[key:string]: LocationInsight},
  insightByLocationLastYear: {} as {[key:string]: LocationInsight},
  insightCumulative: { directionRequests: 0, phoneCalls: 0, websiteVisits: 0, directQueriesDesktop: 0, indirectQueriesDesktop: 0, directQueriesMobile: 0, indirectQueriesMobile: 0 } as LocationInsight,
  insightCumulativeLastMonth: { directionRequests: 0, phoneCalls: 0, websiteVisits: 0, directQueriesDesktop: 0, indirectQueriesDesktop: 0, directQueriesMobile: 0, indirectQueriesMobile: 0 } as LocationInsight,
  insightCumulativeLastYear: { directionRequests: 0, phoneCalls: 0, websiteVisits: 0, directQueriesDesktop: 0, indirectQueriesDesktop: 0, directQueriesMobile: 0, indirectQueriesMobile: 0 } as LocationInsight,
  monthlyChange: { directionRequests: NaN, phoneCalls: NaN, websiteVisits: NaN, directQueriesDesktop: NaN, indirectQueriesDesktop: NaN, directQueriesMobile: NaN, indirectQueriesMobile: NaN } as LocationInsight,
  yearlyChange: { directionRequests: NaN, phoneCalls: NaN, websiteVisits: NaN, directQueriesDesktop: NaN, indirectQueriesDesktop: NaN, directQueriesMobile: NaN, indirectQueriesMobile: NaN }  as LocationInsight,
  selectedAccount: undefined as Account | undefined,
  selectedLocations: [] as string[],
  selectedDateRange: [dateLast30Day(), dateToday()],
  averageTransactionValue: 0,
  loggedIn: async (name: string, email: string, accessToken: string, role?: string) => {
    const newUser: User = {
      name: name,
      email: email,
      role: role || '',
      accessToken: accessToken,
    }
    set({
      loggedInUser: newUser,
      loading: true
    })
    
    console.log("role:", role);
    console.log("Location cache Operation Start=========")
    if (dailyCache == null || Object.keys(dailyCache).length === 0) {
      dailyCache = await GoogleApiService.getCachedDailyMetrics()
      console.log("Got daily cache size=", Object.keys(dailyCache).length)
    } else {
      console.log("Already Have cache",dailyCache, Object.keys(dailyCache).length);
    }
    console.log("Location cache Operation end=========");
    
    if (role === 'admin') {
      GoogleApiService.syncLocationData(newUser)
      const accounts = await GoogleApiService.getAccounts(accessToken)
      if (Object.keys(accounts).length <= 0 ) {
        return
      }
      let first: Account =  accounts[Object.keys(accounts)[0]]
      set({
        accounts: accounts,
        selectedAccount: first,
      })
      const locs = await GoogleApiService.getLocations(newUser, first);
      if (locs.length <= 0) {
        return;
      }
      let newLocations = {} as {[key: string]: any}
      let newLocationNamesByAccountName = get().locationNamesByAccountName
      newLocationNamesByAccountName[first.accountName] = []
      for(let element of locs) {
        newLocations[element.name] = element;
        newLocationNamesByAccountName[first.accountName].push(element.name)
      };
      set({
        locations: newLocations,
        locationNamesByAccountName: newLocationNamesByAccountName,
        selectedLocations: [locs[0].name],
        loading: false
      })
    } else {
      // await ApiService.insertRefLocations(newUser.accessToken);
      // await ApiService.insertRefAccounts(newUser.accessToken);
      const locs = await ApiService.getUserLocations(newUser.accessToken, newUser.email);
      if (locs && locs.locations) {
        const locsList = locs.locations[0].locations;
        const accList = locs.locations[0].groups;
        // const accountList = JSON.parse(locs.locations?.[0]?.accounts);
        console.log('locations List = ', locsList);
        console.log('accounts List = ', accList);
        if (accList) {
          // const accountList = locs.locations[0]?.accounts;
          let accountsMap: any = {};
          accList.forEach((item: any) => {
            let account = {
              accountName: item.accountName,
              name: item.name,
              type: item.type,
              verificationState: item.verificationState,
              vettedState: item.vettedState
            };
            accountsMap[account.accountName] = account
          });
          let first: Account =  accountsMap[Object.keys(accountsMap)[0]]
          set({
            accounts: accountsMap,
            selectedAccount: first,
          })
        }
        if (locsList) {
          let newLocations = {} as {[key: string]: any}
          // let newLocationNamesByAccountName = get().locationNamesByAccountName
          // newLocationNamesByAccountName[first.accountName] = []
          for(let element of locsList) {
            newLocations[element.name] = element;
            // newLocationNamesByAccountName[first.accountName].push(element.name)
          };
          set({
            locations: newLocations,
            // locationNamesByAccountName: newLocationNamesByAccountName,
            selectedLocations: [locsList[0].name],
            loading: false
          })
        }
      }
    }
  },

  logout: async () => {
    let state = get();
    if (state.loggedInUser) {
      set({ 
        loggedInUser: undefined,
        accounts: {},
        locations: {},
        insightByLocation: {},
        insightByLocationLastMonth: {} ,
        insightByLocationLastYear: {},
        insightCumulative: { directionRequests: 0, phoneCalls: 0, websiteVisits: 0, directQueriesDesktop: 0, indirectQueriesDesktop: 0, directQueriesMobile: 0, indirectQueriesMobile: 0 },
        insightCumulativeLastMonth: { directionRequests: 0, phoneCalls: 0, websiteVisits: 0, directQueriesDesktop: 0, indirectQueriesDesktop: 0, directQueriesMobile: 0, indirectQueriesMobile: 0 },
        insightCumulativeLastYear: { directionRequests: 0, phoneCalls: 0, websiteVisits: 0, directQueriesDesktop: 0, indirectQueriesDesktop: 0, directQueriesMobile: 0, indirectQueriesMobile: 0 },
        monthlyChange: { directionRequests: NaN, phoneCalls: NaN, websiteVisits: NaN, directQueriesDesktop: NaN, indirectQueriesDesktop: NaN, directQueriesMobile: NaN, indirectQueriesMobile: NaN },
        yearlyChange: { directionRequests: NaN, phoneCalls: NaN, websiteVisits: NaN, directQueriesDesktop: NaN, indirectQueriesDesktop: NaN, directQueriesMobile: NaN, indirectQueriesMobile: NaN },
        selectedAccount: undefined,
        selectedLocations: [],
        selectedDateRange: [dateLast30Day(), dateToday()],
      })
    }
  },

  updateToken: (newToken: string) => {
    let state = get();
    set({
      loggedInUser: { ...state.loggedInUser, accessToken: newToken } as User
    })
  },

  setSelectedAccount: async  (accountName: string) => {
    if (get().selectedAccount?.accountName === accountName) {
      return
    }

    let account = get().accounts[accountName]!
    let locs = await GoogleApiService.getLocations(get().loggedInUser!, account);
    if (locs.length <= 0) {
      return;
    }

    let newLocations = {} as {[key: string]: any}
    let newLocationNamesByAccountName = get().locationNamesByAccountName
    newLocationNamesByAccountName[account.accountName] = []
    for(let element of locs) {
      newLocations[element.name] = element;
      newLocationNamesByAccountName[account.accountName].push(element.name)
    };

    set({
      locations: newLocations,
      locationNamesByAccountName: newLocationNamesByAccountName,
      selectedAccount: account!,
      selectedLocations:[],
    })
  },

  setSelectedLocations: (locationNames: string[]) => {
    set({
      selectedLocations: locationNames
    })
  },

  setSelectedDateRange: (newRange: [string, string]) => {
    const prevRange = get().selectedDateRange;
    if (prevRange[0] === newRange[0] && prevRange[1] === newRange[1]) {
      return; // no change
    }
    set({
      selectedDateRange: newRange
    })
  },

  postEvent: async (data: EventFormData) => {
    const locs = get().selectedLocations;
    const user = get().loggedInUser;
    console.log("Calling Post Event", locs, user, data)
    const result = await GoogleApiService.postEvent(user!, get().selectedAccount!, locs, data);

    // handle unauthorization issue
    if (result && result.error && result.error.code === 401) {
      set({ 
        loggedInUser: undefined
      })
    }
    return result;
  },

  updatePin: async (locationName: string, coordinates: PinLocation) => {
    const user = get().loggedInUser;
    const result = await GoogleApiService.updatePin(user!, get().selectedAccount!, locationName, coordinates);

    // handle unauthorization issue
    if (result && result.error && result.error.code === 401) {
      set({ 
        loggedInUser: undefined
      })
    }
    return result;
  },

  fetchInsights: async () => {
    const locs = get().selectedLocations;
    const range = get().selectedDateRange;
    const rangeLastMonth = [getLastMonth(range[0]), getLastMonth(range[1])]
    const rangeLastYear = [getLastYear(range[0]), getLastYear(range[1])]
    const user = get().loggedInUser;
    const oldInsightByLocation = get().insightByLocation;
    if (locs.length === 0 && Object.keys(oldInsightByLocation).length !== 0) {
      set({
        insightByLocation: {} as any,
        insightCumulative: { directionRequests: 0, phoneCalls: 0, websiteVisits: 0, directQueriesDesktop: 0, indirectQueriesDesktop: 0, directQueriesMobile: 0, indirectQueriesMobile: 0 } as LocationInsight,
        monthlyChange: { directionRequests: NaN, phoneCalls: NaN, websiteVisits: NaN, directQueriesDesktop: NaN, indirectQueriesDesktop: NaN, directQueriesMobile: NaN, indirectQueriesMobile: NaN },
        yearlyChange: { directionRequests: NaN, phoneCalls: NaN, websiteVisits: NaN, directQueriesDesktop: NaN, indirectQueriesDesktop: NaN, directQueriesMobile: NaN, indirectQueriesMobile: NaN }
      })
      return;
    }
    if (user && locs.length > 0 && range[0] != null && range[1] != null) {
      const res = await calcInsights(get().selectedLocations, range[0], range[1], dailyCache);
      if (res == null) {
        return
      }
      console.log("Cumulative ", res[0], res[1]);
      set({
        insightByLocation: res[0],
        insightCumulative: res[1],
      })
      // Calculate Monthly Difference
      const resLastMonth = await calcInsights(get().selectedLocations, rangeLastMonth[0], rangeLastMonth[1], dailyCache);
      if (resLastMonth == null) {
        return
      }
      console.log("Cumulative Last Month ", resLastMonth[0], resLastMonth[1]);
      const newMonthlyChange: LocationInsight = { 
        directionRequests: 100.0 * (res[1].directionRequests - resLastMonth[1].directionRequests)/(resLastMonth[1].directionRequests), 
        phoneCalls: 100.0 * (res[1].phoneCalls - resLastMonth[1].phoneCalls)/(resLastMonth[1].phoneCalls),
        websiteVisits: 100.0 * (res[1].websiteVisits - resLastMonth[1].websiteVisits)/(resLastMonth[1].websiteVisits),
        directQueriesDesktop: 100.0 * (res[1].directQueriesDesktop - resLastMonth[1].directQueriesDesktop)/(resLastMonth[1].directQueriesDesktop),
        indirectQueriesDesktop: 100.0 * (res[1].indirectQueriesDesktop - resLastMonth[1].indirectQueriesDesktop)/(resLastMonth[1].indirectQueriesDesktop),  
        directQueriesMobile: 100.0 * (res[1].directQueriesMobile - resLastMonth[1].directQueriesMobile)/(resLastMonth[1].directQueriesMobile), 
        indirectQueriesMobile: 100.0 * (res[1].indirectQueriesMobile - resLastMonth[1].indirectQueriesMobile)/(resLastMonth[1].indirectQueriesMobile), 
      }
      set({
        insightByLocationLastMonth: resLastMonth[0],
        insightCumulativeLastMonth: resLastMonth[1],
        monthlyChange: newMonthlyChange
      })
      // Calculate Yearly Difference
      const resLastYear = await calcInsights(get().selectedLocations, rangeLastYear[0], rangeLastYear[1], dailyCache);
      if (resLastYear == null) {
        return
      }
      console.log("Cumulative Last Year ", resLastYear[0], resLastYear[1]);
      const newYearlyChange: LocationInsight = { 
        directionRequests: 100.0 * (res[1].directionRequests - resLastYear[1].directionRequests)/(resLastYear[1].directionRequests), 
        phoneCalls: 100.0 * (res[1].phoneCalls - resLastYear[1].phoneCalls)/(resLastYear[1].phoneCalls),
        websiteVisits: 100.0 * (res[1].websiteVisits - resLastYear[1].websiteVisits)/(resLastYear[1].websiteVisits),
        directQueriesDesktop: 100.0 * (res[1].directQueriesDesktop - resLastYear[1].directQueriesDesktop)/(resLastYear[1].directQueriesDesktop),
        indirectQueriesDesktop: 100.0 * (res[1].indirectQueriesDesktop - resLastYear[1].indirectQueriesDesktop)/(resLastYear[1].indirectQueriesDesktop),  
        directQueriesMobile: 100.0 * (res[1].directQueriesMobile - resLastYear[1].directQueriesMobile)/(resLastYear[1].directQueriesMobile), 
        indirectQueriesMobile: 100.0 * (res[1].indirectQueriesMobile - resLastYear[1].indirectQueriesMobile)/(resLastYear[1].indirectQueriesMobile), 
      }
      set({
        insightByLocationLastMonth: resLastYear[0],
        insightCumulativeLastMonth: resLastYear[1],
        yearlyChange: newYearlyChange
      })
    }
  },

  getAverageTransactionValue: async() => {
    const locs = get().selectedLocations;
    const loc_ids = locs.map(loc => loc.slice(10));
    console.log("selectedLocations::", locs);
    if (locs.length) {
      const result = await GoogleApiService.getAvgTransactionValue(loc_ids);
      set({
        averageTransactionValue: result,
      });
    } else {
      set({
        averageTransactionValue: 0
      });
    }
  },

  updateAverageTransactionValue: async(newAverageTransactionValue: number) => {
    const locs = get().selectedLocations;
    const loc_ids = locs.map(loc => loc.slice(10));
    await GoogleApiService.updateAvgTransactionValueWithLocations(loc_ids, newAverageTransactionValue);
    set({
      averageTransactionValue: newAverageTransactionValue
    });
  }
}),   
{
  name: "mapforce-storage", // unique name
  getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
}));
