import * as React from 'react';
import '../components/style.css'
import Sidebar from 'components/Sidebar'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Header from 'components/Header'
import EventForm from 'components/EventForm';


function PromosContent(props: any) {
  const [type, setType] = React.useState('');

  const handleChange = (event: any) => {
    setType(event.target.value);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} paddingLeft="20px">
        <Grid item xs={4}>
          <Typography variant="h1" component="div" align="left">Promos And Updates</Typography>
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="Select Type"
              onChange={handleChange}
            >
              <MenuItem value={'covid'}>Covid Update</MenuItem>
              <MenuItem value={'offer'}>Offer</MenuItem>
              <MenuItem value={'new'}>What's New</MenuItem>
              <MenuItem value={'event'}>Event</MenuItem>
              <MenuItem value={'product'}>Product</MenuItem>

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={8} />
      </Grid><br />
      <EventForm />
    </Box>
  )
}

function Promos() {
  return (
    <div className="container">
      <Sidebar />
      <div className="content-area">
        <Header />  
        <div className="hero">
          <PromosContent />
        </div>
      </div>
    </div>
  )
}


export default Promos;
