import LocationSelector from './LocationSelector';

interface HeaderProps {

}

function Header(props: HeaderProps) {  
  return(
    <div className="header">
      <LocationSelector/>
    </div>
  )
}

export default Header;
