import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Alert, Link, List, ListItem, Snackbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RepositoryImpl } from "data/RepositoryImpl";
import GoogleLogin from "react-google-login";

import { ApiService } from 'data/ApiService';
import { GoogleApiService } from 'data/GoogleApiService';

import "components/style.css";
import logo from 'images/logo_normal.svg'; 
import banner from 'images/banner-mapforce.png';
import googleIcon from 'images/google.svg';

type FormValues = {
  email: string
  password: string
};

function LoginForm() {
  const navigate = useNavigate();
  const loggedIn = RepositoryImpl((state) => state.loggedIn)
  const user = RepositoryImpl((state) => state.loggedInUser)
  const updateToken = RepositoryImpl((state) => state.updateToken)
  const {register, handleSubmit, formState: {isValid}} = useForm<FormValues>()

  const [error, setError] = useState('')

  const responseGoogle = (response: any) => {
    if(response.accessToken) {
      loggedIn(response.profileObj.name, response.profileObj.email, response.accessToken, 'admin');
      GoogleApiService.refreshTokenSetup(response, user!, updateToken);
      navigate("/performance", { replace: true });
    }
  };

  const onLogin: SubmitHandler<FormValues> = async (data) => {
    const res = await ApiService.login(data)
    if (res.error && res.error.message) {
      setError(res.error.message)
    } else {
      const { user: { firstName, lastName, email }, token } = res;
      loggedIn(`${firstName} ${lastName}`, email, token, 'member');
      navigate('/performance')
    }
  };

  return (
    <div className="login-container">
      <Snackbar
        open={!!error}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setError('')}
      >
        <Alert severity='error'>{error}</Alert>
      </Snackbar>
      <Box
        sx={{
          flex: 1,
          display: 'flex'
        }}
        component='div'
      >
        <Box
          component='div'
          sx={{
            margin: 'auto',
            width: '100%',
            maxWidth: '360px'
          }}
        >
          <img alt="Mapforce" src={logo} width="200"/>
          <Typography component="div" sx={{ marginTop: '40px', fontSize: 36, fontWeight: 600 }}>
            Sign in to MapForce
          </Typography>
          <form onSubmit={handleSubmit(onLogin)}>
            <List>
              <ListItem sx={{ px: 0, py: 0 }}>
                <TextField
                  id="outlined-basic"
                  type="email"
                  label="Email Address*"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  {...register('email', { required: true })}
                />
              </ListItem>
              <ListItem sx={{ px: 0, py: 0 }}>
                <TextField
                  id="outlined-basic"
                  label="Password*"
                  type="password"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  {...register('password', { required: true })}
                />
              </ListItem>
              <ListItem sx={{ px: 0 }}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ textTransform: 'unset' }}
                  disabled={!isValid}
                >
                  Sign In
                </Button>
              </ListItem>
              <ListItem sx={{ px: 0 }}>
                <GoogleLogin
                  clientId="583193747837-l6e4oh0927e40n65rjnpm7aofup84n7n.apps.googleusercontent.com"
                  scope="profile email https://www.googleapis.com/auth/business.manage"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy="single_host_origin"
                  render={props => (
                    <Button
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        width: '100%',
                        textTransform: 'unset',
                        color: '#344054',
                        fontSize: '16px',
                        border: 'solid 1px #D0D5DD'
                      }}
                      onClick={props.onClick}
                    >
                      <img src={googleIcon} alt='icon' />
                      Sign in with Google
                    </Button>
                  )}
                />
              </ListItem>
              <ListItem sx={{ px: 0 }}>
                <Box
                  component='div'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    marginTop: '20px',
                    width: '100%'
                  }}
                >
                  <Typography variant='body2' sx={{ color: '#667085' }}>
                    Don't have an account?
                  </Typography>
                  <Link
                    sx={{ fontSize: 14, textDecoration: 'unset', cursor: 'pointer' }}
                    onClick={() => navigate('/register')}
                  >
                    Create new one
                  </Link>
                </Box>
              </ListItem>
            </List>
          </form>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: { xs: 'none', lg: 'block' },
          backgroundImage: `url(${banner})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
        component='div'
      />
    </div>
  );
};

export default LoginForm;