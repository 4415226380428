import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { RepositoryImpl } from "data/RepositoryImpl";

interface LocationSelectorProps {

}

// const ITEM_HEIGHT = 38;
// const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function LocationSelector(props: LocationSelectorProps) {
  const [storeCodes, setStoreCodes] = useState<string[]>([])
  const [showStoreCodesLocations, setShowStoreCodesLocations] = useState<{open: boolean, anchorEl: HTMLButtonElement | null}>({
    open: false,
    anchorEl: null
  })
  const accounts = RepositoryImpl((state) => state.accounts)
  const selectedAccount =  RepositoryImpl((state) => state.selectedAccount)
  const setSelectedAccount =  RepositoryImpl((state) => state.setSelectedAccount)
  const getAverageTransactionValue = RepositoryImpl((state) => state.getAverageTransactionValue)
  const handleAccountChange = (event: SelectChangeEvent<any>) => {
    setSelectedAccount(event.target.value)
  };
  const buttonRef = useRef(null);

  const accountNameArray = () => {
    return Object.keys(accounts)
  }

  const locations = RepositoryImpl((state) => state.locations)
  const selectedLocationNames = RepositoryImpl((state) => state.selectedLocations)
  const setSelectedLocationNames = RepositoryImpl((state) => state.setSelectedLocations)

  const handleChange = (event: SelectChangeEvent<typeof selectedLocationNames>) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 1000) {
      if (value.includes('all')) {
        if (value.length === Object.entries(locations).length + 1) {
          setSelectedLocationNames([])
        } else {
          setSelectedLocationNames(Object.entries(locations).map(([key, location]) => key))
        }
      } else {
        setSelectedLocationNames(
          // On autofill we get a stringified value.
          typeof value === 'string' ? [] : value.filter((val) => val !== 'all'),
        );
      }
    }
  }

  const handleClickItem = (value: string) => {
    if (selectedLocationNames.indexOf(value) > -1) {
      return setSelectedLocationNames(selectedLocationNames.filter((sloc) => sloc !== value))
    }
    return setSelectedLocationNames([...selectedLocationNames, value])
  }

  const onSelectAllLocationsWithStoreCodes = () => {
    const keys = locationsWithStoreCodes.map(([key, location]) => key);
    if (storeCodeLocationsStatus === 'checked') {
      setSelectedLocationNames(selectedLocationNames.filter(sl => !keys.includes(sl)))
    } else {
      const missedKeys = keys.filter(k => !selectedLocationNames.includes(k))
      setSelectedLocationNames([...selectedLocationNames, ...missedKeys])
    }
  }

  const handleStoreCodesChange = (event: React.ChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    const codes = value.split(',')
    setStoreCodes(codes)
  }

  const render = (selectedNames: string[]): string => {
    return selectedNames.map(selectedName => locations[selectedName].title).join(", ")
  }

  const locationsWithStoreCodes = useMemo(() => {
    const availaleLocations = Object.entries(locations).filter(([key, location]) => storeCodes.map((sc) => sc.trimStart().trimEnd()).includes(location.storeCode));
    return availaleLocations;
  },[locations, storeCodes]);

  const storeCodeLocationsStatus = useMemo(() => {
    const keys = locationsWithStoreCodes.map(([key, location]) => key);
    if (keys.every(k => selectedLocationNames.includes(k))) {
      return 'checked'
    } else if (keys.some(k => selectedLocationNames.includes(k))) {
      return 'intermidate'
    }
    return 'noChecked'
  }, [locationsWithStoreCodes, selectedLocationNames]);

  useEffect(() => {
    getAverageTransactionValue();
  }, [selectedLocationNames]);

  return (
    <Box
      component='div'
      sx={{ display: 'flex', width: '100%', flexDirection: { xs: 'column', lg: 'row' } }}
    >
      <FormControl sx={{ my: 1, mx: { xs: 0, lg: 1 }, width: '100%', maxWidth: '400px' }}>
        <InputLabel id="account-label">
          Group
        </InputLabel>
          <Select
            labelId="account-label"
            id="account-select"
            value={selectedAccount? selectedAccount.accountName: ""}
            label="Group"
            onChange={handleAccountChange}
          >
          {accountNameArray().map((accountName) => (
            <MenuItem  key={accountName} value={accountName}>{accountName}</MenuItem>
          ))}
          </Select>
      </FormControl>
      <FormControl sx={{ my: 1, mx: { xs: 0, lg: 1 }, width: '100%', maxWidth: '400px' }}>
        <InputLabel id="location-multiple-checkbox-label">Locations</InputLabel>
        <Select
          labelId="location-multiple-checkbox-label"
          id="location-multiple-checkbox"
          multiple
          value={selectedLocationNames}
          onChange={handleChange}
          input={<OutlinedInput label="Location" />}
          renderValue={render}
          MenuProps={MenuProps}
        >
          <MenuItem value='all'>
            <Checkbox
              sx={{ padding: '4px 10px 4px 0' }}
              indeterminate={!!selectedLocationNames.length && selectedLocationNames.length !== Object.entries(locations).length}
              checked={selectedLocationNames.length === Object.entries(locations).length}
            />
            Select All
          </MenuItem>
          <Box component='div' sx={{ padding: '4px 10px 4px 0' }} style={{padding: '10px 16px'}}>
            Locations selected ({selectedLocationNames.length})
          </Box>
          {Object.entries(locations).map(([key, location]) => (
            <MenuItem key={key} value={key} sx={{ alignItems: 'start' }}>
              <Checkbox sx={{ padding: '4px 10px 4px 0' }} checked={selectedLocationNames.findIndex((selected) => {return selected === key}) > -1} />
              {/* @ts-ignore */}
              <Box component='div'>
                <ListItemText primary={location.title} />
                {location.storefrontAddress && location.storefrontAddress.addressLines.length ? (
                  <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14 }}>
                    {`${location.storefrontAddress.addressLines[0]}, ${location.storefrontAddress.locality}, ${location.storefrontAddress.administrativeArea}, ${location.storefrontAddress.postalCode}`}
                  </Typography>
                ) : null}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ my: 1, mx: { xs: 0, lg: 1 }, width: '100%', maxWidth: '400px' }} variant='outlined'>
        <InputLabel htmlFor="store-codes-search">Search</InputLabel>
        <OutlinedInput
          id='store-codes-search'
          label='Search'
          startAdornment={
            <InputAdornment position='start'>
              Store Codes:
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position='end'>
              <IconButton ref={buttonRef} sx={{ background: 'transparent' }} onClick={(event) => setShowStoreCodesLocations({ open: true, anchorEl: event.currentTarget })}>
                <ArrowDropDownIcon />
              </IconButton>
            </InputAdornment>
          }
          sx={{ pr: 0 }}
          value={storeCodes.toString()}
          onChange={handleStoreCodesChange}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              setShowStoreCodesLocations({
                open: true,
                anchorEl: buttonRef.current
              })
            }
          }}
        />
      </FormControl>
      {locationsWithStoreCodes.length ? (
        <Popover
          id='locations-for-store-codes'
          open={showStoreCodesLocations.open}
          anchorEl={showStoreCodesLocations.anchorEl}
          onClose={() => setShowStoreCodesLocations({ open: false, anchorEl: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{ '.MuiPaper-root': { mt: 1 } }}
        >
          <Box component='div' sx={{ width: 400, py: 1 }}>
            <MenuItem onClick={onSelectAllLocationsWithStoreCodes}>
              <Checkbox
                sx={{ padding: '4px 10px 4px 0' }}
                indeterminate={storeCodeLocationsStatus === 'intermidate'}
                checked={storeCodeLocationsStatus === 'checked'}
              />
              Select All
            </MenuItem>
            {locationsWithStoreCodes.map(([key, location]) => (
              <MenuItem
                key={key}
                sx={{
                  alignItems: 'start',
                  backgroundColor: selectedLocationNames.findIndex((selected) => {return selected === key}) > -1 ? 'rgba(96, 51, 250, 0.08)' : 'unset',
                  '&:hover': {
                    backgroundColor: selectedLocationNames.findIndex((selected) => {return selected === key}) > -1 ? 'rgba(96, 51, 250, 0.1)' : 'rgba(0, 0, 0, 0.1',
                  }
                }}
                onClick={() => handleClickItem(key)}
              >
                <Checkbox sx={{ padding: '4px 10px 4px 0' }} checked={selectedLocationNames.findIndex((selected) => {return selected === key}) > -1} />
                {/* @ts-ignore */}
                <Box component='div'>
                  <ListItemText primary={location.title} />
                  {location.storefrontAddress && location.storefrontAddress.addressLines.length ? (
                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14 }}>
                      {`${location.storefrontAddress.addressLines[0]}, ${location.storefrontAddress.locality}, ${location.storefrontAddress.administrativeArea}, ${location.storefrontAddress.postalCode}`}
                    </Typography>
                  ) : null}
                  <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 12 }}>
                    {`Store Code: ${location.storeCode}`}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Box>
        </Popover>
      ) : null}
    </Box>
  );
}

export default LocationSelector;
